import request from '../utils/request'
// import { encodeQuery } from '../utils/helperFunctions'
import { FETCH_TYPES } from '../utils/constants'
import { REST_API } from './API_Routes'
import ServiceBase from './ServiceBase'

export default class AuthService extends ServiceBase {
  static async login(body) {
    const token = false
    const option = {
      method: FETCH_TYPES.POST,
      body: body,
    }
    const response = await request(REST_API.LOGIN, option, token)
    return response
  }

  static logout = async () => {
    const option = {
      method: FETCH_TYPES.POST,
    }
    const response = await request(REST_API.LOGOUT, option)
    return response
  }
}
