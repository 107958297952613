export const GENDER_OPTIONS = [
  { value: 'M', label: 'Male' },
  { value: 'F', label: 'Female' },
  { value: 'O', label: 'Other' },
]

export const TICKET_STATUS_OPTIONS = [
  { label: 'Pending', value: '1' },
  { label: 'Waiting Approval', value: '2' },
  { label: 'Ongoing', value: '3' },
  { label: 'Waiting for parts', value: '4' },
  { label: 'Waiting for customer', value: '5' },
  { label: 'Closed', value: '6' },
  { label: 'Canceled', value: '7' },
]

export const TICKET_TYPE_OPTIONS =[
  { label: 'REPAIR', value: 1 },
  { label: 'MAINTENANCE', value: 2 },
  { label: 'INSTALLATION', value: 3 },
]

export const STATUS_OPTIONS = [
  { label: 'Active', value: '1' },
  { label: 'Inactive', value: '0' },
]

export const UNIT_OPTIONS = [
  { label: 'KG', value: 'KG' },
  { label: 'GRAM', value: 'GRAM' },
  { label: 'LITRE', value: 'LITRE' },
  { label: 'METER', value: 'METER' },
  { label: 'INCH', value: 'INCH' },
  { label: 'FEET', value: 'FEET' },
  { label: 'PIECE', value: 'PIECE' },
  { label: 'OTHER', value: 'OTHER' },
]
// Status id as key
export const status = {
  0: { label: 'Inactive', color: 'danger' },
  1: { label: 'Active', color: 'success' },
  2: { label: 'Pending', color: 'secondary' },
  3: { label: 'Approved', color: 'success' },
  4: { label: 'Completed', color: 'success' },
  5: { label: 'Ongoing', color: 'warning' },
  6: { label: 'Cancelled', color: 'danger' },
  7: { label: 'Rejected', color: 'danger' },
}

export const USER_TYPES = [
  { label: 'Project Manager', value: 1 },
  { label: 'Employee', value: 2 },
  { label: 'Admin', value: 3 },
  { label: 'Warehouse/Logistic  Admin', value: 4 },
  { label: 'Accounts/Finance Admin', value: 5 },
]
export const statusColor = (statusId, type = 'bg-') => {
  return `${type}${status[statusId].color}`
}

export const statusName = (statusId) => {
  return `${status[statusId].label}`
}
