/* eslint-disable react/prop-types */
import React, { useMemo, useState, useEffect, useCallback } from "react";
import {
  Row,
  Col,
  Form,
  Card,
  Button,
  FormGroup,
  Image,
} from "react-bootstrap";
import { useForm, useFieldArray } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ReactstrapInput from "../../../components/form/input-fields/ReactstrapInput";
import ReactSelect from "../../../components/form/input-fields/ReactSelect";
//
import PlusIcon from "../../../assets/icons/common/plus-white.svg";
import PlusBlackIcon from "../../../assets/icons/common/plus-black.svg";
import UserIcon from "../../../assets/icons/sidebar/users-1.svg";

import { CLASSES } from "../../../assets/styles/classes";
import { CustomerService } from "../../../services";
import { setAlert, setLoading } from "../../../store/reducers";
import { REST_API } from "../../../services/API_Routes";
import { LOADING_NAME } from "../../../utils/constants";
import AsyncReactSelect from "../../../components/form/input-search/AsyncReactSelect";
import SelectStatusElement from "../../../components/extras/SelectStatusElement";
const customer_form_schema = yup.object().shape({
  customer_first_name: yup.string().required("Please write first name."),
  customer_email_1: yup.string().required("Please write email."),
  customer_phone1: yup.string().required("Please write phone no."),
  ref_customer_state_id: yup.object().shape({
    label: yup.string(),
    value: yup.string(),
  }),
  ref_customer_city_id: yup.object().shape({
    label: yup.string(),
    value: yup.string(),
  }),
});

export default function CreateCustomer({
  customerId,
  bloodGroupOptions,
  customerTypeOptions,
}) {
  // const dispatch = useDispatch()
  // const history = useHistory()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id: customerID } = useParams();
  const [isLoading, setIsLoading] = React.useState(false);
  const [states, setStates] = React.useState([]);
  const [cities, setCities] = useState([]);
  const [schema, setSchema] = React.useState(customer_form_schema);

  const {
    formState: { errors },
    watch,
    reset,
    control,
    setValue,
    handleSubmit,
  } = useForm({
    mode: "all",
    defaultValues: {
      // type_id: null,
      // numbers: [{ number: '' }],
    },
    resolver: yupResolver(schema),
  });

  // const { remove, fields, append } = useFieldArray({
  //   name: 'numbers',
  //   control,
  // })

  const getData = async () => {
    dispatch(setLoading({ key: LOADING_NAME.COMMON, value: true }));
    try {
      const response = await CustomerService.view({
        route: REST_API.CUSTOMER_EDIT_VIEW + customerID,
      });
      if (response.success) {
        // Reset the form with all the relevant fields from the response
        const externalReferralInfo = JSON.parse(response.data.external_referral_info || '{}');

        reset({
          ...response?.data,
          ref_customer_state_id: response?.data?.ref_customer_state_id
            ? {
                value: response?.data?.ref_customer_state_id,
                label: response?.data?.state_name,
              }
            : null,
          ref_customer_city_id: response?.data?.ref_customer_city_id
            ? {
                value: response?.data?.ref_customer_city_id,
                label: response?.data?.city_name,
              }
            : null,
          zip_code: response?.data?.zip_code || "",

          referred_by_customer_id: response?.data?.referred_by_customer_id
            ? {
                value: response?.data?.referred_by_customer_id,
                label: response?.data?.referred_name_by_id,
              }
            : null,

            external_customer_name: externalReferralInfo.customer_name || '',
            external_customer_phone: externalReferralInfo.phone_number || '',
            external_customer_email: externalReferralInfo.email || '',
        });

        // Fetch cities based on the state if it is present
        if (response?.data?.ref_customer_state_id) {
          fetchCities(response.data.ref_customer_state_id);
        }
      }
    } catch (error) {
      console.error("Error fetching customer data:", error);
    } finally {
      dispatch(setLoading({ key: LOADING_NAME.COMMON, value: false }));
    }
  };

  React.useEffect(() => {
    if (customerID) {
      getData();
    }
  }, [customerID]);

  useEffect(() => {
    const fetchStates = async () => {
      try {
        const response = await CustomerService.getStates(REST_API.STATES_LIST);
        if (response.success) {
          const options = response.data.map((item) => ({
            label: item.state_name,
            value: item.state_id,
          }));
          setStates(options);
        }
      } catch (error) {
        console.error("Error fetching states:", error);
      }
    };

    fetchStates();
  }, []);

  const fetchCities = async (stateId) => {
    if (!stateId) {
      setCities([]);
      setValue("ref_customer_city_id", null); // Clear city value
      return;
    }
    try {
      const response = await CustomerService.getCities(
        REST_API.CITY_LIST,
        stateId
      );
      if (response.success) {
        const cityOptions = response.data.map((item) => ({
          label: item.city_name,
          value: item.city_id,
        }));
        setCities(cityOptions);
      }
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  // const handleStateChange = (selectedOption) => {
  //   setValue("ref_customer_state_id", selectedOption);
  //   setValue("ref_customer_city_id", null);
  //   fetchCities(selectedOption?.value); // Fetch cities for selected state
  // };

  React.useEffect(() => {
    if (watch('ref_customer_state_id')?.value) {
      setValue("ref_customer_city_id", null);
      fetchCities(watch('ref_customer_state_id')?.value); // Fetch cities for selected state
    }
  },[watch('ref_customer_state_id')])

  const onSubmit = async (values) => {
    values.ref_customer_state_id = values.ref_customer_state_id?.value || null;
    values.ref_customer_city_id = values.ref_customer_city_id?.value || null;
    values.referred_by_customer_id =
      values.referred_by_customer_id?.value || null;

    // return console.log(values);
    if (customerID) {
      values.id = customerID;
    }

    setIsLoading(true);

    try {
      const response = await CustomerService.create_or_update_customer(values);
      if (response?.success) {
        dispatch(
          setAlert({
            show: true,
            success: true,
            title: `Successfully Completed`,
            message: "",
          })
        );
        navigate(-1);
      } else {
        dispatch(
          setAlert({
            show: true,
            message: response?.error_msg || "Something went wrong",
          })
        );
      }
    } catch (error) {
      console.log("error", error);
      dispatch(setAlert({ show: true, message: "Something went wrong" }));
    } finally {
      setIsLoading(false);
    }
  };

  const loadSuggestions = React.useCallback((query, callback) => {
    CustomerService.get({
      page: 1,
      per_page: 10,
      customer_active: "1",
      customer_name: query,
    })
      .then((res) => {
        const suggestions = res?.data?.data?.map((item) => ({
          label:
            item.customer_first_name +
            (item.customer_last_name ? " " + item.customer_last_name : ""),
          value: item.customer_id,
        }));
        callback(suggestions);
      })
      .catch((error) => {
        alert(error);
      });
  }, []);

  const clientName = watch("referred_by_customer_id");

  return (
    <div className="p-4">
      <div className={CLASSES.content_between + "mb-4"}>
        <h4 className={CLASSES.items_center}>
          {" "}
          <Image src={UserIcon} className="me-3" /> Customers Information
        </h4>
        <Button className={CLASSES.items_center + "bg-blue"}>
          <Image src={PlusIcon} className={"me-2"} /> Add Customer and Ticket
        </Button>
      </div>
      <Form className="d-flex flex-column justify-content-between">
        <div className="bg-white card black-shadow-15 p-4 px-5">
          <Row className="mb-3 text-bold">
            <Col>Personal info.</Col>
          </Row>
          <Row className="">
            <ReactstrapInput
              {...{
                label: "First Name",
                name: "customer_first_name",
                type: "text",
                placeholder: "Enter customer first name",
                errorMessage: errors?.customer_first_name?.message,
                control,
                required: true,
              }}
            />

            <ReactstrapInput
              {...{
                label: "Last Name",
                name: "customer_last_name",
                type: "text",
                placeholder: "Enter customer last name",
                errorMessage: errors?.customer_last_name?.message,
                control,
              }}
            />
            <ReactstrapInput
              {...{
                label: "Business/ Company name (optional)",
                name: "customer_business_name",
                type: "text",
                placeholder: "Enter company name if have",
                errorMessage: errors?.customer_business_name?.message,
                control,
              }}
            />

            <ReactstrapInput
              {...{
                label: "Email id 1",
                name: "customer_email_1",
                type: "email",
                placeholder: "Enter customer Email id",
                errorMessage: errors?.customer_email_1?.message,
                control,
                required: true,
              }}
            />

            <ReactstrapInput
              {...{
                label: "Email id 2",
                name: "customer_email_2",
                type: "email",
                placeholder: "Enter customer Email id",
                errorMessage: errors?.customer_email_2?.message,
                control,
              }}
            />
            {/* {fields.map((item, i) => (
              <ReactstrapInput
                {...{
                  label: 'Phone Number ' + (i + 1),
                  name: `numbers.${i}.number`,
                  type: 'number',
                  placeholder: 'Enter customer Phone number ' + (i + 1),
                  errorMessage: errors.numbers?.[i]?.number?.message,
                  control,
                }}
              />
            ))} */}

            <ReactstrapInput
              {...{
                label: "Phone Number 1",
                name: "customer_phone1",
                type: "number",
                placeholder: "Enter customer Phone number 1",
                errorMessage: errors.customer_phone1?.message,
                control,
                required: true,
              }}
            />

            <ReactstrapInput
              {...{
                label: "Phone Number 2",
                name: `customer_phone2`,
                type: "number",
                placeholder: "Enter customer Phone number 2",
                errorMessage: errors.customer_phone2?.message,
                control,
              }}
            />

            {/* <Col lg='4' md='3' className={CLASSES.items_center}>
              <button
                type='button'
                onClick={() => append(1)}
                className={
                  CLASSES.items_center +
                  'bg-white text-gray border w-50 p-2 py-1 mb-3 mt-4'
                }
              >
                <Image src={PlusBlackIcon} className={'me-2'} /> Add more number
              </button>
            </Col> */}
          </Row>
          <Row className="my-4 text-bold">
            <Col>Address</Col>
          </Row>
          <Row>
            {/* <Col lg='4' md='3' className={CLASSES.items_center}>
              <button
                type='button'
                onClick={() => { }}
                className={
                  CLASSES.items_center +
                  'bg-white text-gray border w-50 p-2 py-1 mb-3 mt-4'
                }
              >
                <Image src={PlusBlackIcon} className={'me-2'} /> Add more address
              </button>
            </Col> */}

            {/* <DatePicker
            {...{
              label: 'Trade License Date Of Validity',
              name: 'trade_licence_expiry',
              placeholder: 'Choose validity date',
              errorMessage: errors?.trade_licence_expiry?.message,
              control,
            }}
          /> */}
            {/* <ReactstrapInput
              {...{
                label: 'State',
                name: 'ref_customer_state_id',
                type: 'text',
                placeholder: 'Select here',
                errorMessage: errors?.ref_customer_state_id ?.message,
                control,
              }}
            /> */}

            <ReactSelect
              label="State"
              name="ref_customer_state_id"
              placeholder="Select here"
              errorMessage={errors?.ref_customer_state_id?.value?.message}
              control={control}
              options={states}
              isClearable
              // onChange={handleStateChange}
            />

            <ReactSelect
              label="City"
              name="ref_customer_city_id"
              placeholder="Select here"
              errorMessage={errors?.ref_customer_city_id?.value?.message}
              control={control}
              options={cities}
              // onChange={(selectedOption) =>
              //   setValue("ref_customer_city_id", selectedOption)
              // }
              isClearable
            />

            {/* <DatePicker
            {...{
              label: 'Bus. Segment License Date Of Validity',
              name: 'licence_expiry',
              placeholder: 'Choose validity date',
              errorMessage: errors?.licence_expiry?.message,
              control,
            }}
          /> */}
            {/* <ReactstrapInput
              {...{
                label: "City",
                name: "bin",
                type: "text",
                placeholder: "Select here",
                errorMessage: errors?.ref_customer_city_id?.message,
                control,
              }}
            /> */}
            {/* <ReactstrapInput
              {...{
                label: 'State/province/Region',
                name: 'tin',
                type: 'text',
                placeholder: 'Select here',
                errorMessage: errors?.tin?.message,
                control,
              }}
            /> */}
            <ReactstrapInput
              {...{
                label: "Zip/postal Code",
                name: "zip_code",
                type: "text",
                placeholder: "Enter postal code",
                errorMessage: errors?.zip_code?.message,
                control,
              }}
            />

            <ReactstrapInput
              {...{
                as: "textarea",
                label: "Address",
                name: "customer_address",
                type: "text",
                placeholder: "Write address",
                errorMessage: errors?.customer_address?.message,
                control,
                noGrid: true,
                rows: 5
              }}
            />

            {/* <ReactSelect
            {...{
              label: 'Customer Type',
              name: 'type_id',
              placeholder: 'Choose type',
              errorMessage: errors?.type_id?.message,
              options: customerTypeOptions,
              control,
            }}
          /> */}
          </Row>
          <Row className="my-4 text-bold">
            <Col>Referral information</Col>
          </Row>
          <Row>
            {/* <ReactstrapInput
              {...{
                type: 'number',
                disabled: watch('cash'),
                name: 'referred_by_customer_id',
                label: 'Referred By',
                placeholder: 'Select here',
                errorMessage: errors?.referred_by_customer_id?.message,
                control,
              }}
            /> */}

            <AsyncReactSelect
              {...{
                label: "Customer Name",
                name: "referred_by_customer_id",
                type: "text",
                placeholder: "Select Customer",
                errorMessage: errors?.ref_client_id?.value?.message,
                loadSuggestions,
                control,
                required: true,
                noGrid: false,
                isClearable: true,
              }}
            />
          </Row>

          <Row className="my-4 text-bold">
            <Col>If the referred person does not exist</Col>
          </Row>

          <Row>
            <ReactstrapInput
              {...{
                type: "text",
                disabled: !!clientName,
                name: "external_customer_name",
                label: "Referred Customer Name",
                placeholder: "Write here",
                control,
              }}
            />

            <ReactstrapInput
              {...{
                type: "number",
                disabled: !!clientName,
                name: "external_customer_phone",
                label: "Phone Number",
                placeholder: "Write here",
                errorMessage: errors?.referred_by_customer_id?.message,
                control,
              }}
            />

            <ReactstrapInput
              {...{
                type: "text",
                disabled: !!clientName,
                name: "external_customer_email",
                label: "Email id",
                placeholder: "Write here",
                errorMessage: errors?.external_customer_email?.message,
                control,
              }}
            />
          </Row>

          <Row>
            {/* <ReactstrapInput
              {...{
                type: 'number',
                disabled: watch('cash'),
                name: 'credit_limit',
                label: 'Phone/Email id',
                placeholder: 'Write here',
                errorMessage: errors?.credit_limit?.message,
                control,
              }}
            /> */}
          </Row>
        </div>
        <Row className="mt-5">
          <Col className="d-flex mt-1 mx-5">
            <FormGroup className="w-100">
              <Button
                className="mr-0 w-100 bg-blue border-0"
                color="primary"
                onClick={handleSubmit(onSubmit)}
              >
                Submit
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
