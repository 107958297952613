// import ServiceBase from './ServiceBase';
import AuthService from './AuthService'
import REST_API from './API_Routes'
import Tickets from './Tickets'
import Projects from './Projects'
import Employees from './Employees'
import Sites from './Sites'
import Equipments from './Equipments'
import Warehouses from './Warehouses'
import EquipmentRequests from './EquipmentRequests'
import Requisitions from './Requisitions'
import Categories from './Categories'
import Notifications from './Notifications'
import Customer from './Customer'
import Asset from './Asset'
import Invoice from './Invoice'
import Appointment from './Appointment'

const TicketsService = new Tickets(REST_API.TICKET_LIST)
const ProjectsService = new Projects(REST_API.PROJECT_LIST)
const SitesService = new Sites(REST_API.SITES_LIST)
const EquipmentsRequestService = new EquipmentRequests(
  REST_API.EQUIPMENT_REQUESTS,
)
const EquipmentsService = new Equipments(REST_API.EQUIPMENTS_LIST)
const RequisitionService = new Requisitions(REST_API.REQUISITION_LIST)
const EmployeesService = new Employees(REST_API.EMPLOYEE_LIST)
const WarehouseService = new Warehouses(REST_API.WAREHOUSE_LIST)
const CategoriesService = new Categories(REST_API.CATEGORIES)
const NotificationsService = new Notifications(REST_API.NOTIFICATIONS)
// const TicketsService = new ServiceBase(REST_API.GROUP);
const CustomerService = new Customer(REST_API.CUSTOMER)
const AssetService = new Asset(REST_API.ASSET)
const InvoiceService = new Invoice(REST_API.INVOICE)
const AppointmentService = new Appointment(REST_API.APPOINTMENT)

export {
  AuthService,
  TicketsService,
  ProjectsService,
  SitesService,
  EquipmentsRequestService,
  EquipmentsService,
  EmployeesService,
  WarehouseService,
  RequisitionService,
  CategoriesService,
  NotificationsService,
  CustomerService,
  AssetService,
  InvoiceService,
  AppointmentService
}
