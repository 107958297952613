import React, { Fragment } from 'react'
import { Dropdown, Image } from 'react-bootstrap'
import DropDownIcon from '../../assets/icons/common/down-arrow.svg'
import DropDownIconBold from '../../assets/icons/common/down-arrow-solid.svg'
import { CLASSES, COLORS } from '../../assets/styles/classes'

export default function DropDownX({
  isBoldIcon = false,
  items = ['Newest', 'Item-2', 'Item-3', 'Item-4'],
  label = '',
  handleSearch = () => {},
}) {
  const [selectedItem, setSelectedItem] = React.useState(items[0])
  const handleSelect = (item) => {
    setSelectedItem(item)
    handleSearch(item)
  }
  return (
    <Dropdown  className='border rounded ms-2 p-1' style={{ backgroundColor: COLORS.blue_10 }}>
      <Dropdown.Toggle
        id='dropdown-button-dark-example1'
        className={CLASSES.content_between}
        variant={COLORS.blue_10}
        style={{ backgroundColor: COLORS.blue_10 }}
      >
        <div>
          {label} <span className='text-bold'>{selectedItem}</span>
        </div>{' '}
        <Image
          src={isBoldIcon ? DropDownIconBold : DropDownIcon}
          className={'ms-2'}
        />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {items.map((item, i) => (
          <Fragment key={i}>
            {i !== 0 && <Dropdown.Divider />}
            <Dropdown.Item onClick={() => handleSelect(item)} active={i == 100}>
              {item}
            </Dropdown.Item>
          </Fragment>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}
