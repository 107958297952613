import React, { useState } from 'react'
import { Button, FormGroup } from 'react-bootstrap'
import { Puff, ThreeDots } from 'react-loader-spinner'
import { useNavigate } from 'react-router-dom'
//
import { CLASSES, COLORS } from '../../assets/styles/styles'

export default function ButtonX({
  label = 'Click',
  icon = undefined,
  isLoading = false,
  onClick,
  color = COLORS.theme,
  textColor = COLORS.white,
  style,
  ...rest
}) {
  // const navigate = useNavigate();
  const [opacity, setOpacity] = useState(1)

  const onBUttonClick = () => {
    setTimeout(() => {
      setOpacity((prevValue) => {
        if (prevValue === 1) {
          return prevValue
        } else return 0.8
      })
    }, 100)
  }
  return (
    <Button
      disabled={isLoading}
      className={'mr-0 w-100 border-0 ' + CLASSES.content_center}
      color='primary'
      onClick={(e) => {
        onBUttonClick()
        onClick(e)
      }}
      onMouseEnter={() => setOpacity(0.8)}
      onMouseDown={() => setOpacity(0.9)}
      onMouseLeave={() => setOpacity(1)}
      style={{
        backgroundColor: color,
        minHeight: 40,
        color: textColor,
        fontSize: 14,
        ...(!isLoading && { opacity }),
        ...style,
      }}
      {...rest}
    >
      <ThreeDots
        visible={isLoading}
        height='10'
        width='80'
        color='white'
        radius='9'
        ariaLabel='three-dots-loading'
        wrapperStyle={{}}
        wrapperClass=''
      />
      {!isLoading ? label : ''}
    </Button>
  )
}
