import React, { useEffect, useState } from 'react'
import { Button, Container, Image } from 'react-bootstrap'
import moment from 'moment'
import { CLASSES } from '../../../assets/styles/classes'
import PlusIcon from '../../../assets/icons/common/plus-white.svg'
import TicketIcon from '../../../assets/icons/sidebar/ticket.svg'
import DropDownIcon from '../../../assets/icons/common/down-arrow-solid.svg'
import Table from '../../../components/table/Table'
import DropDownX from '../../../components/extras/DropDownX'
import SearchFilter from '../../../components/extras/SearchFilter'
import { useNavigate } from 'react-router-dom'
import HorizontalNavbar from '../components/HorizontalNavbar'
import HeaderInfo from '../../../components/extras/HeaderInfo'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentPage } from '../../../store/reducers'
import { PAGINATION_TYPES } from '../../../utils/constants'
import TableAction from '../components/TableAction'
import getPaginationData from '../../../store/actions/pagination'
import { TicketsService } from '../../../services'
import { viewDateFormat } from '../../../utils/helperFunctions'
import { TICKET_STATUS_OPTIONS } from '../../../utils/static_data'

const columns = [
  // {
  //   name: 'Ticket Id',
  //   width: '250px',
  //   style: {
  //     fontWeight: 'bold',
  //   },
  //   cell: ({ ticket_id }) => ticket_id,
  // },
  {
    name: 'Ticket Id',
    width: '150px',
    style: {
      fontWeight: 'bold',
    },
    cell: ({ ticket_no }) => ticket_no,
  },
  {
    name: 'Customer Name',
    minWidth: '180px',
    cell: ({ customer }) =>
      customer?.customer_first_name + ' ' + customer?.customer_last_name,
  },
  {
    name: 'Phone Number',
    minWidth: '180px',
    cell: ({ customer }) => customer?.customer_phone1 || 'N/A',
  },
  {
    name: 'Email',
    minWidth: '180px',
    cell: ({ contact_email }) => contact_email || 'N/A',
  },
  {
    name: 'Issue',
    minWidth: '180px',
    cell: ({ item_name }) => item_name,
  },
  {
    name: 'Status',
    minWidth: '180px',
    center: true,
    cell: ({ref_ticket_status_id}) => (
      <div
        style={{ borderRadius: 20, border: '1px solid #F3922F' }}
        className={CLASSES.items_center + 'p-1 px-2 text-orange'}
      >
        {TICKET_STATUS_OPTIONS.find((item) => item.value == ref_ticket_status_id).label}
        {/* <Image src={DropDownIcon} className={'ms-2'} /> */}
      </div>
    ),
  },
  {
    name: 'Date',
    // minWidth: '180px',
    cell: ({ created_at }) => viewDateFormat(created_at),
  },
  {
    name: 'Actions',
    width: '120px',
    style: { paddingRight: '30px' },
    center: true,
    allowOverflow: true,
    cell: (row) => <TableAction data={row} />,
  },
]

export default function Tickets() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { items, currentPage, rowsPerPage, total } = useSelector(
    ({ pagination }) => pagination[PAGINATION_TYPES.TICKETS],
  )
  const isLoading = useSelector(
    ({ loader }) => loader[PAGINATION_TYPES.TICKETS],
  )
  const [searchQuery, setSearchQuery] = useState({})

  useEffect(() => {
    dispatch(
      getPaginationData({
        key: PAGINATION_TYPES.TICKETS,
        ServiceName: TicketsService,
        page: currentPage,
        per_page: rowsPerPage,
        order_by: 'desc',
        ...searchQuery,
      }),
    )
  }, [currentPage, rowsPerPage, searchQuery])

  const handleSearch = (query) => {
    setSearchQuery(query)
    dispatch(
      setCurrentPage({
        key: PAGINATION_TYPES.TICKETS,
        currentPage: 1,
      }),
    )
  }

  return (
    <Container fluid className='p-4 py-2 mt-2'>
      <div>
        <HeaderInfo
          Img={TicketIcon}
          title='All Ticket'
          element={`Total Ticket: ${total}`}
        />
        {/* <div className={CLASSES.content_between}>
           <h4 className={CLASSES.items_center}>
             {' '}
             <Image src={TicketIcon} className='me-3' /> All Ticket
           </h4>
           <div className={CLASSES.items_center + 'text-bold'}>
             Total Ticket: 500
           </div>
         </div> */}

        <div className={CLASSES.content_between + 'my-2'}>
          <HorizontalNavbar handleSearch={handleSearch} />
          <Button
            onClick={() => navigate('/ticket/create')}
            className={CLASSES.items_center + 'bg-blue'}
          >
            <Image src={PlusIcon} className={'me-2'} /> Add New Ticket
          </Button>
        </div>

        <div className='mb-5 mt-3 pt-4 border-radius-10 black-shadow-15'>
          <div className={CLASSES.content_between + 'px-4 mb-4'}>
            <div className='px-0 px-md-2'>
              <SearchFilter handleSearch={handleSearch} name='ticket_id' placeholder='Ticket Id' />
            </div>

            <div className={CLASSES.content_between + 'px-0 px-md-2'}>
              {/* <DropDownX label='Issue : ' /> */}
              <DropDownX
                label='Sort by : '
                items={['Newest', 'Oldest']}
                handleSearch={(item) => handleSearch({order_by: item == 'Newest' ? 'desc' : 'asc'})}
              />
            </div>
          </div>

          <Table
            columns={columns}
            data={items || []}
            progressPending={isLoading}
            customStyle={{
              rows: {
                style: {
                  borderTop: '0px solid #E0E0E0',
                  borderBottom: '1px solid #E0E0E0 !important',
                  display: 'flex !important',
                  alignItems: 'center !important',
                  padding: 15,
                },
              },
            }}
          />
        </div>
      </div>
    </Container>
  )
}
