import React from 'react';
import CardElement from './CardElement';

function CustomerTicketTab({ data = [] }) {  // Default value is an empty array
  return (
    <div className='w-100'>
      {data.length > 0 ? (
        data.map((ticket, index) => (
          <CardElement key={index} data={ticket} page='ticket' />
        ))
      ) : (
        <CardElement data={{ status: 0 }} page='ticket' />
      )}
    </div>
  );
}

export default CustomerTicketTab;
