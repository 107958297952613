/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, FormGroup, Image } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import ReactstrapInput from '../../../components/form/input-fields/ReactstrapInput';
import UserIcon from '../../../assets/icons/sidebar/users-1.svg';
import { CLASSES } from '../../../assets/styles/classes';
import { setAlert, setLoading } from '../../../store/reducers';
import { REST_API } from '../../../services/API_Routes';
import { AssetService } from '../../../services';
import { LOADING_NAME } from '../../../utils/constants';

const assetSchema = yup.object().shape({
  asset_name: yup.string().required('Please enter asset name.'),
  asset_qty: yup.number().required('Please enter quantity.').positive().integer(),
  asset_price: yup.number().required('Please enter price.').positive(),
  
});

export default function CreateCustomerAsset() {
  const [isLoading, setIsLoading] = useState(false);
  const [warrantyUnit, setWarrantyUnit] = useState('');
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id: assetID } = useParams();

  const {
    formState: { errors },
    control,
    handleSubmit,
    reset,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(assetSchema),
  });

  const getData = async () => {
    dispatch(setLoading({ key: LOADING_NAME.COMMON, value: true }));
    try {
      const response = await AssetService.view({
        route: REST_API.ASSET_EDIT_VIEW + assetID,
      });

      if (response.success) {
        if(response.data.asset_warranty_is_month){
           setWarrantyUnit(response.data.asset_warranty_is_month)
        }
        reset({...response?.data});
      }
    } catch (error) {
      console.error("Error fetching asset data:", error);
    } finally {
      dispatch(setLoading({ key: LOADING_NAME.COMMON, value: false }));
    }
  };

  useEffect(() => {
    if (assetID) {
      getData();
    }
  }, [assetID]);

  const onSubmit = async (values) => {
    // Check if warranty period is set, and at least one warranty unit is selected
    if (values.asset_warranty_period && !warrantyUnit) {
      dispatch(setAlert({ show: true, message: 'Please select a warranty unit (Month or Year).' }));
      return;
    }

    if(values.asset_warranty_period && warrantyUnit){
      values.asset_warranty_is_month = warrantyUnit;
    }

    if (assetID) {
      values.id = assetID;
    }

    setIsLoading(true);

    try {
      const response = await AssetService.create_or_update_asset(values);
      if (response?.success) {
        dispatch(setAlert({
          show: true,
          success: true,
          title: 'Successfully Completed',
          message: '',
        }));
        navigate(-1);
      } else {
        dispatch(setAlert({
          show: true,
          message: response?.error_msg || 'Something went wrong',
        }));
      }
    } catch (error) {
      console.log("error", error);
      dispatch(setAlert({ show: true, message: 'Something went wrong' }));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className='p-4'>
      <div className={CLASSES.content_between + 'mb-4'}>
        <h4 className={CLASSES.items_center}>
          <Image src={UserIcon} className='me-3' /> Asset Information
        </h4>
      </div>
      <Form className='d-flex flex-column justify-content-between'>
        <div className='bg-white card black-shadow-15 p-4 px-5'>
          <Row className='mb-3 text-bold'>
            <Col>Asset info.</Col>
          </Row>
          <Row>
            <ReactstrapInput
              label='Asset Name'
              name='asset_name'
              type='text'
              placeholder='Enter asset name'
              errorMessage={errors?.asset_name?.message}
              control={control}
            />
            <ReactstrapInput
              label='Brand Name'
              name='asset_brand_name'
              type='text'
              placeholder='Enter brand name'
              control={control}
            />
            <ReactstrapInput
              label='Model'
              name='asset_model_no'
              type='text'
              placeholder='Enter model'
              control={control}
            />
          </Row>
          <Row>
            <ReactstrapInput
              label='Quantity'
              name='asset_qty'
              type='number'
              placeholder='Enter quantity'
              errorMessage={errors?.asset_qty?.message}
              control={control}
            />
            <ReactstrapInput
              label='Price'
              name='asset_price'
              type='number'
              placeholder='Enter price'
              errorMessage={errors?.asset_price?.message}
              control={control}
            />
            <Col lg={4} md={12} className={CLASSES.items_center}>
              <ReactstrapInput
                label='Guarantee'
                name='asset_warranty_period'
                type='number'
                placeholder='Enter Guarantee'
                errorMessage={errors?.asset_warranty_period?.message}
                control={control}
              />
              <div className={CLASSES.content_center + 'mt-2 ms-4'}>
                <input 
                  type='radio' 
                  name='asset_warranty_is_month' 
                  value='1' 
                  checked={warrantyUnit === 1}
                  control={control}
                  onChange={() => setWarrantyUnit(1)} 
                />
                <div className='ms-2'>Month</div>
              </div>
              <div className={CLASSES.content_center + 'mt-2 ms-4'}>
                <input 
                  type='radio' 
                  name='asset_warranty_is_month' 
                  checked={warrantyUnit === 2}
                  value='2' 
                  onChange={() => setWarrantyUnit(2)} 
                />
                <div className='ms-2'>Year</div>
              </div>
            </Col>
          </Row>
        </div>
        <Row className='mt-5'>
          <Col className='d-flex mt-1 mx-5'>
            <FormGroup className='w-100'>
              <Button
                className='mr-0 w-100 bg-blue border-0'
                color='primary'
                onClick={handleSubmit(onSubmit)}
                disabled={isLoading}
              >
                {isLoading ? 'Submitting...' : 'Submit'}
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
