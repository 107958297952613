import React, { useState, useEffect } from 'react'
import {
  Dropdown,
  DropdownButton,
  Button,
  Col,
  Container,
  Form,
  Image,
  InputGroup,
} from 'react-bootstrap'
import moment from 'moment'
import { CLASSES } from '../../../assets/styles/classes'
import MoreIcon from '../../../assets/icons/common/more.svg'
import EditIcon from '../../../assets/icons/common/edit.svg'
import DeleteIcon from '../../../assets/icons/common/delete.svg'
import PlusIcon from '../../../assets/icons/common/plus-white.svg'
import UserIcon from '../../../assets/icons/sidebar/users-1.svg'
import Table from '../../../components/table/Table'
import { fakeData } from '../../../utils/faker'
import { useDispatch, useSelector } from 'react-redux'
import getPaginationData from '../../../store/actions/pagination'
import DropDownX from '../../../components/extras/DropDownX'
import SearchFilter from '../../../components/extras/SearchFilter'
import { useNavigate } from 'react-router-dom'
import ActionElements from '../../../components/extras/ActionElements'
import { PAGINATION_TYPES } from '../../../utils/constants'
import { CustomerService } from '../../../services'
import { setCurrentPage } from '../../../store/reducers'
import { addOrUpdatePaginationData } from '../../../store/actions/pagination'
import REST_API from '../../../services/API_Routes'
import CustomPagination from '../../../components/table/CustomPagination'

// Define DropDownItems as a separate functional component
const DropDownItems = ({ itemId, navigate }) => {
  return (
    <Dropdown.Item
      onClick={() => navigate(`/customer/details`, { state: { id: itemId } })}
      eventKey={itemId}
    >
      Details
    </Dropdown.Item>
  )
}



const columns = (navigate, deleteClick) => [
  {
    name: 'Customer Id',
    // width: '150px',
    style: { fontWeight: 'bold' },
    cell: ({ customer_id }) => customer_id,
  },
  {
    name: 'Customer Name',
    minWidth: '180px',
    cell: ({ customer_first_name }) => customer_first_name,
  },
  {
    name: 'Phone Number',
    minWidth: '180px',
    cell: ({ customer_phone1 }) => customer_phone1,
  },
  {
    name: 'Email',
    minWidth: '180px',
    cell: ({ customer_email_1 }) => customer_email_1,
  },
  {
    name: 'Actions',
    width: '120px',
    style: { paddingRight: '30px' },
    center: true,
    allowOverflow: true,
    cell: (row) => (
      <ActionElements
        id={row.customer_id || 1}
        editClick={() => navigate(`/customer/edit/${row.customer_id}`)}
        deleteClick={() => deleteClick(row.customer_id)} // Pass as a function reference
        dropdownItems={<DropDownItems itemId={row.customer_id} navigate={navigate} />}
      />
    ),
  },
]

export default function Customer() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { items, currentPage, rowsPerPage } = useSelector(
    ({ pagination }) => pagination[PAGINATION_TYPES.CUSTOMERS],
  )
  const isLoading = useSelector(({ loader }) => loader[PAGINATION_TYPES.CUSTOMERS])
  const [searchQuery, setSearchQuery] = useState({})

  useEffect(() => {
    dispatch(
      getPaginationData({
        key: PAGINATION_TYPES.CUSTOMERS,
        ServiceName: CustomerService,
        page: currentPage,
        per_page: rowsPerPage,
        ...searchQuery,
      }),
    )
  }, [currentPage, rowsPerPage, searchQuery])

  const handleSearch = (query) => {
    setSearchQuery(query)
    dispatch(
      setCurrentPage({
        key: PAGINATION_TYPES.CUSTOMERS,
        currentPage: 1,
      }),
    )
  }

  const deleteClick = async (id) => {
    dispatch(
      addOrUpdatePaginationData({
        id,
        key: PAGINATION_TYPES.CUSTOMERS,
        ServiceName: CustomerService,
        primaryKey: 'customer_id',
        route: REST_API.CUSTOMER_DELETE + id,
        value: { customer_active: 0 },
      }),
    )
  }

  return (
    <Container fluid className='p-4 py-2 mt-2'>
      <div>
        <div className={CLASSES.content_between}>
          <h4 className={CLASSES.items_center}>
            <Image src={UserIcon} className='me-3' /> All Customers
          </h4>
          <div className={CLASSES.items_center + ' text-bold'}>
            Total Customer: 500
          </div>
        </div>

        <div className='mb-5 mt-3 pt-4 border-radius-10 black-shadow-15'>
          <div className={CLASSES.content_between + ' px-4 mb-4'}>
            <Button
              onClick={() => navigate('/customer/create')}
              className={CLASSES.items_center + ' bg-blue'}
            >
              <Image src={PlusIcon} className={'me-2'} /> Add New Customer
            </Button>
            <div className={CLASSES.content_end}>
              <div className='px-0 px-md-2' style={{ width: '45%' }}>
                <SearchFilter handleSearch={handleSearch} placeholder='Customer Name'/>
              </div>
              {/* <div className='px-0 px-md-2' style={{ minWidth: '35%' }}>
                <DropDownX label='Sort by : ' />
              </div> */}
            </div>
          </div>

          <Table
            columns={columns(navigate, deleteClick)} // Pass deleteClick here
            data={items}
            progressPending={isLoading}
            paginationType={PAGINATION_TYPES.CUSTOMERS}
            customStyle={{
              rows: {
                style: {
                  borderTop: '0px solid #E0E0E0',
                  borderBottom: '1px solid #E0E0E0 !important',
                  display: 'flex !important',
                  alignItems: 'center !important',
                  padding: 15,
                },
              },
            }}

            conditionalRowStyles={[
              {
                when: ({ customer_active }) =>
                  customer_active == 0,
                style: {
                  backgroundColor: '#FFB0B0',
                },
              },
            ]}
          />
        </div>
      </div>
    </Container>
  )
}
