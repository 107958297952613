import React from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { COLORS } from '../../../assets/styles/classes'
import Form from 'react-bootstrap/Form'
import { useForm } from 'react-hook-form'
import ReactstrapInput from '../../../components/form/input-fields/ReactstrapInput'
import { Row } from 'react-bootstrap'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import ReactSelect from '../../../components/form/input-fields/ReactSelect'
import request from '../../../utils/request'
import { FETCH_TYPES } from '../../../utils/constants'
import ButtonX from '../../../components/common/ButtonX'
import { viewDateFormat } from '../../../utils/helperFunctions'
const schema = yup.object().shape({
  //   title: yup.string().required('Please enter title.'),
  message_content: yup.string().required('Please enter message.'),
  status: yup.object().shape({
    value: yup.string().required('Please select an option.'),
    label: yup.string(),
  }),
})
function VerticalModal(props) {
  const [loading, setLoading] = React.useState(false)
  const {
    formState: { errors, isValid },
    watch,
    reset,
    control,
    setValue,
    handleSubmit,
  } = useForm({
    mode: 'all',
    defaultValues: {
      title: '',
      comment: '',
    },
    resolver: yupResolver(schema),
  })

  const onSubmit = async (values) => {
    values.message_type = values.status?.value
    values.ticket_id = props?.ticket_id
    values.customer_id = props?.customer_id
    values.sent_at = viewDateFormat(new Date(), 'YYYY-MM-DD HH:mm:ss')

    props?.setMessages((prev) => [...prev, values])
    props?.onHide()
    reset()


    //   console.log(values)
    setLoading(true)
    const formData = new FormData()
    Object.keys(values).forEach((key) => {
      if (values[key]) {
        formData.append(key, values[key])
      }
    })
    const option = {
      method: FETCH_TYPES.POST,
      body: formData,
    }
    const token = true
    const isFormData = true
    const response = await request('ticket/message', option, token, isFormData)
    if (response?.success) {
    //   props?.onHide()
      // props?.setMessages((prev) => [...prev, values])
    //   reset()
    }
    setLoading(false)
  }

  return (
    <Modal
      {...props}
      style={{ fontSize: '14px' }}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton> </Modal.Header>
      <Modal.Body>
        <Row>
          {/* <ReactstrapInput
            {...{
              label: 'Title',
              name: 'title',
              type: 'text',
              placeholder: 'Enter title here',
              errorMessage: errors?.title?.message,
              control,
              lg: '12',
              xl: '12',
              xxl: '12',
              required: true,
            }}
          /> */}

          <ReactstrapInput
            {...{
              label: 'Message',
              name: 'message_content',
              as: 'textarea',
              rows: 5,
              type: 'text',
              placeholder: 'Enter message here',
              errorMessage: errors?.message_content?.message,
              control,
              lg: '12',
              xl: '12',
              xxl: '12',
              required: true,
            }}
          />
        </Row>

        <Form>
          {/* <Form.Group className="mb-3" controlId="title">
                        <Form.Label>Title</Form.Label>
                        <Form.Control style={{ fontSize: '14px' }} type="email" placeholder="Enter title here" />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="comment">
                        <Form.Label>Comment</Form.Label>
                        <Form.Control style={{ fontSize: '14px' }} as="textarea" rows={5} placeholder='Enter your comment' />
                    </Form.Group> */}
        </Form>
      </Modal.Body>
      <Modal.Footer className='d-flex justify-content-between align-items-center'>
        {/* <Form.Select style={{ fontSize: '14px' }} className='w-50'>
          <option>Message</option>
          <option>Email</option>
        </Form.Select> */}
        <ReactSelect
          style={{ fontSize: '14px' }}
          className='w-50'
          placeholder='Select Message Type'
          name='status'
          label='Message send to'
          control={control}
          options={[
            { value: '1', label: 'Whatsapp' },
            { value: '2', label: 'Email' },
            { value: '3', label: 'SMS' },
          ]}
          required
          errorMessage={errors?.status?.value?.message}
        />
        {/* <Button
          onClick={handleSubmit(onSubmit)}
          style={{
            backgroundColor: COLORS.blue,
            color: 'white',
            fontSize: '14px',
          }}
          className='px-4'
        >
          Send
        </Button> */}
        <ButtonX
          loading={loading}
          onClick={handleSubmit(onSubmit)}
          label='Send'
          style={{ width: '100px' }}
        />
      </Modal.Footer>
    </Modal>
  )
}

function CommunicateModal({ setMessages = () => {}, data = {} }) {
  const [modalShow, setModalShow] = React.useState(false)

  return (
    <>
      <div className='mb-3 w-100 text-end'>
        <Button
          variant={COLORS.blue}
          style={{ backgroundColor: COLORS.blue, color: 'white' }}
          onClick={() => setModalShow(true)}
        >
          + Add New Message
        </Button>
      </div>

      <VerticalModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        setMessages={setMessages}
        ticket_id={data?.ticket?.ticket_id}
        customer_id={data?.ticket?.customer?.customer_id}
      />
    </>
  )
}

export default CommunicateModal
