import React, { useEffect, useCallback } from 'react'
import { Row, Col, Form, Image } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useForm, useFieldArray } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import ReactstrapInput from '../../../components/form/input-fields/ReactstrapInput'
import ReactstrapFileInput from '../../../components/form/input-fields/ReactstrapFileInput'
//
import TicketIcon from '../../../assets/icons/sidebar/ticket.svg'
import { CLASSES } from '../../../assets/styles/classes'
import ReactSelect from '../../../components/form/input-fields/ReactSelect'
import DatePicker from '../../../components/form/input-fields/DatePicker'
import TimePicker from '../../../components/form/input-fields/TimePicker'
import {
  AssetService,
  CustomerService,
  TicketsService,
} from '../../../services'
import {
  setFailedAlert,
  setLoading,
  setSuccessAlert,
} from '../../../store/reducers'
import { useNavigate, useParams } from 'react-router-dom'
import REST_API from '../../../services/API_Routes'
import { viewDateFormat, viewTimeFormat } from '../../../utils/helperFunctions'
import BillingDetailsCreate from '../components/BillingDetailsCreate'
import AsyncReactSelect from '../../../components/form/input-search/AsyncReactSelect'
import {
  TICKET_STATUS_OPTIONS,
  TICKET_TYPE_OPTIONS,
} from '../../../utils/static_data'
import TicketAttachments from '../components/TicketAttachments'
import ButtonX from '../../../components/common/ButtonX'
import RelevantAssets from '../components/RelevantAssets'
import moment from 'moment'

const ticketSchema = yup.object().shape({
  ticket_title: yup.string().required('Ticket title is required'),
  ref_ticket_status_id: yup.object().shape({
    value: yup.string().required('Ticket status is required'),
    label: yup.string(),
  }),
  ref_customer_id: yup.object().shape({
    value: yup.string().required('Customer is required'),
    label: yup.string(),
  }),
  item_name: yup.string().required('Item name is required'),
  issue_type: yup.string().required('Issue type is required'),
  ticket_type_id: yup.object().shape({
    value: yup.string().required('Ticket type is required'),
    label: yup.string(),
  }),
  issue_date: yup.date().required('Issue date is required'),
  pick_time: yup.date().required('Pick time is required'),
  analysis_date: yup.date().required('Analysis date is required'),
  repairing_date: yup.date().required('Repairing date is required'),
  delivery_date: yup.date().required('Delivery date is required'),
  delivery_time: yup.date().required('Delivery time is required'),
  ticket_attachments_files: yup
    .array()
    .required('Attachments are required') // The array must be present
    .min(1, 'At least one file is required'),
  assets: yup
    .array()
    .required('Assets are required') // The array must be present
    .min(1, 'At least one asset is required'),
})

export default function CreateTicket() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id: ticketId } = useParams()

  const {
    formState: { errors },
    watch,
    reset,
    setValue,
    control,
    handleSubmit,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      assets: [],
      ticket_attachments_files: [],
      serviceCharge: 0,
      discount: 0,
    },
    resolver: yupResolver(ticketSchema),
  })

  const {
    remove: removeAsset,
    fields: assetsFields,
    append: assetAppend,
  } = useFieldArray({
    name: 'assets',
    control,
  })

  const {
    remove: removeAttachment,
    fields: attachmentFields,
    append: attachmentAppend,
  } = useFieldArray({
    name: 'ticket_attachments_files',
    control,
  })
// console.log(watch('pick_time'));
  //conditional fields set
  useEffect(() => {
    if (watch('asset')) {
      const alreadyListed = assetsFields.find(
        (item) => item.value === watch('asset').value,
      )
      if (!alreadyListed) {
        assetAppend(watch('asset'))
      }
    }
  }, [watch('asset')])

  //conditional fields set
  useEffect(() => {
    if (watch('assets').length === 0) {
      setValue('asset', null)
    } else if (watch('ticket_attachments_files').length === 0) {
      setValue('ticket_attachments', null)
    }
  }, [watch('assets').length, watch('ticket_attachments_files').length])

  //conditional fields set
  useEffect(() => {
    if (watch('ticket_attachments')) {
      attachmentAppend(Object.values(watch('ticket_attachments')))
    }
  }, [watch('ticket_attachments')])

  useEffect(() => {
    if (ticketId) {
      getData()
    }
  }, [ticketId])

  const getData = async () => {
    dispatch(setLoading({ value: true }))
    try {
      const response = await TicketsService.view({
        route: REST_API.TICKET_VIEW + ticketId,
      })
      if (response.success) {
        // Reset the form with all the relevant fields from the response
        reset({
          ...response?.data?.ticket,
          ref_ticket_status_id: response?.data?.ticket?.ref_ticket_status_id
            ? {
                value: response?.data?.ticket?.ref_customer_state_id,
                label: response?.data?.ticket?.state_name,
              }
            : null,
          ref_customer_id: response?.data?.ticket?.customer && {
            value: response?.data?.ticket?.customer?.customer_id,
            label: response?.data?.ticket?.customer?.customer_first_name + ' ' +
              response?.data?.ticket?.customer?.customer_last_name,
          },
          ticket_type_id: response?.data?.ticket?.ticket_type_id && {
            value: response?.data?.ticket?.ticket_type_id,
            label: response?.data?.ticket?.ticket_type_name,
          },

          ref_ticket_status_id: response?.data?.ticket?.ref_ticket_status_id && TICKET_STATUS_OPTIONS.find(
            (item) => item.value == response?.data?.ticket?.ref_ticket_status_id
          ),
          ticket_type_id: response?.data?.ticket?.ticket_type_id && TICKET_TYPE_OPTIONS.find(
            (item) => item.value == response?.data?.ticket?.ticket_type_id
          ),

          pick_time: moment(response?.data?.ticket?.pick_time, 'HH:mm:ss').toDate(),
          delivery_time: moment(response?.data?.ticket?.delivery_time, 'HH:mm:ss').toDate(),

          ticket_attachments_files: response?.data?.ticket?.attachments || [],
          assets: response?.data?.ticket?.assets.map((item) => ({ value: item.asset_id, label: item.asset_name, ...item })) || [],
          serviceCharge: response?.data?.ticket?.billing?.service_charge || 0,
          discount: response?.data?.ticket?.billing?.discount || 0,
        })
      }
    } catch (error) {
      console.error('Error fetching customer data:', error)
    } finally {
      dispatch(setLoading({ value: false }))
    }
  }

  const loadCustomersSuggestions = useCallback((query, callback) => {
    CustomerService.get({
      page: 1,
      per_page: 10,
      customer_name: query,
    })
      .then((res) => {
        const suggestions = res?.data?.data?.map((item) => ({
          label: item.customer_first_name + ' ' + item.customer_last_name,
          value: item.customer_id,
        }))
        callback(suggestions)
      })
      .catch((error) => {
        alert(error)
      })
  }, [])

  const loadAssetsSuggestions = useCallback((query, callback) => {
    AssetService.get({
      page: 1,
      per_page: 10,
      asset_name: query,
    })
      .then((res) => {
        const suggestions = res?.data?.data?.map((item) => ({
          label: item.asset_name,
          title: item.asset_name,
          value: item.asset_id,
          ...item,
        }))
        callback(suggestions)
      })
      .catch((error) => {
        alert(error)
      })
  }, [])

  const billingData = () => {
    const subTotal = assetsFields.reduce(
      (acc, curr) => acc + Number(curr?.asset_price) * curr?.asset_qty,
      0,
    )
    let total_due =
      subTotal +
      subTotal * 0.05 +
      Number(watch('serviceCharge')) -
      Number(watch('discount'))
    return {
      service_charge: watch('serviceCharge'),
      discount: watch('discount'),
      tax: subTotal * 0.05 || 0,
      total_amount: `${total_due.toFixed(2) || 0}`,
    }
  }

  const onSubmit = async ({
    asset,
    assets,
    ticket_attachments_files,
    ...values
  }) => {
    values.ref_ticket_status_id = values.ref_ticket_status_id?.value || null
    values.ticket_type_id = values.ticket_type_id?.value || null
    values.ref_customer_id = values.ref_customer_id?.value || null
    values.assets_id = assets?.map((item) => item.value)
    values.issue_date = viewDateFormat(values.issue_date, 'YYYY-MM-DD')
    values.pick_time = viewTimeFormat(values.pick_time)
    values.analysis_date = viewDateFormat(values.analysis_date, 'YYYY-MM-DD')
    values.repairing_date = viewDateFormat(values.repairing_date, 'YYYY-MM-DD')
    values.delivery_date = viewDateFormat(values.delivery_date, 'YYYY-MM-DD')
    values.delivery_time = viewTimeFormat(values.delivery_time)
    values.ticket_attachments =
      ticket_attachments_files &&
      Object.values(ticket_attachments_files?.filter((item) => item?.size))
    // return console.log(values)
    if (ticketId) {
      values.id = ticketId
      values.ticket_attachments_json = JSON.stringify(
        ticket_attachments_files?.filter((item) => !item?.size),
      )
    }

    dispatch(setLoading({ value: true }))

    try {
      const response = await TicketsService.create_or_update_ticket({
        ...values,
        ...billingData(),
      })
      if (response?.success) {
        dispatch(setSuccessAlert())
        navigate(-1)
      } else {
        dispatch(
          setFailedAlert({
            message: response?.error_msg || 'Something went wrong',
          }),
        )
      }
    } catch (error) {
      console.log('error', error)
      dispatch(setFailedAlert({ message: 'Something went wrong' }))
    } finally {
      dispatch(setLoading({ value: false }))
    }
  }

  return (
    <div className='p-4'>
      <div className={CLASSES.content_between + 'mb-4'}>
        <h4 className={CLASSES.items_center}>
          {' '}
          <Image src={TicketIcon} className='me-3' /> Ticket Information
        </h4>
        {/* <div style={{ color: '#18C935' }}>
          Customer was successfully created.
        </div> */}
      </div>
      <Form className='d-flex flex-column justify-content-between'>
        <div className='bg-white card black-shadow-15 p-4 px-5'>
          <Row className='mb-3 text-bold'>
            <Col>New Ticket Create</Col>
          </Row>
          <Row className=''>
            <ReactstrapInput
              {...{
                label: 'Ticket Title',
                name: 'ticket_title',
                type: 'text',
                placeholder: 'Enter title',
                errorMessage: errors?.ticket_title?.message,
                control,
                required: true,
              }}
            />
            <AsyncReactSelect
              {...{
                label: 'Customer',
                name: 'ref_customer_id',
                placeholder: 'Select Customer',
                errorMessage: errors?.ref_customer_id?.value?.message,
                loadSuggestions: loadCustomersSuggestions,
                control,
                required: true,
              }}
            />
            <ReactSelect
              {...{
                label: 'Ticket Status',
                name: 'ref_ticket_status_id',
                placeholder: 'Select ticket status',
                errorMessage: errors?.ref_ticket_status_id?.value?.message,
                options: TICKET_STATUS_OPTIONS,
                control,
                required: true,
              }}
            />
            <ReactstrapInput
              {...{
                label: 'Item name',
                name: 'item_name',
                type: 'text',
                placeholder: 'Enter item name',
                errorMessage: errors?.item_name?.message,
                control,
                required: true,
              }}
            />
            <ReactstrapInput
              {...{
                label: 'Issue type',
                name: 'issue_type',
                type: 'text',
                placeholder: 'Enter issue type',
                errorMessage: errors?.issue_type?.message,
                control,
                required: true,
              }}
            />
            <ReactSelect
              {...{
                label: 'Ticket type',
                name: 'ticket_type_id',
                placeholder: 'Select ticket type',
                errorMessage: errors?.ticket_type_id?.value?.message,
                options: TICKET_TYPE_OPTIONS,
                control,
                required: true,
              }}
            />
          </Row>
          <hr />
          <Row className='my-4 text-bold'>
            <Col>Device Pick</Col>
          </Row>
          <Row>
            <DatePicker
              {...{
                label: 'Issue Date', //2023-5-12
                name: 'issue_date',
                placeholder: 'Select Date',
                errorMessage: errors?.issue_date?.message,
                control,
                required: true,
              }}
            />
            <TimePicker
              {...{
                label: 'Pick Time (approximate)',
                name: 'pick_time',
                placeholder: 'Select Time',
                errorMessage: errors?.pick_time?.message,
                control,
                required: true,
              }}
            />

            <DatePicker
              {...{
                label: 'Analysis Date (approximate)',
                name: 'analysis_date',
                placeholder: 'Select Date',
                errorMessage: errors?.analysis_date?.message,
                control,
                required: true,
              }}
            />
            <DatePicker
              {...{
                label: 'Repairing  Date (approximate)',
                name: 'repairing_date',
                placeholder: 'Select Date',
                errorMessage: errors?.repairing_date?.message,
                control,
                required: true,
              }}
            />
            <DatePicker
              {...{
                label: 'Delivery  Date (approximate)',
                name: 'delivery_date',
                placeholder: 'Select Date',
                errorMessage: errors?.delivery_date?.message,
                control,
                required: true,
              }}
            />
            <TimePicker
              {...{
                label: 'Delivery Time (approximate)',
                name: 'delivery_time',
                placeholder: 'Select Time',
                errorMessage: errors?.delivery_time?.message,
                control,
                required: true,
              }}
            />
            <ReactstrapInput
              {...{
                as: 'textarea',
                rows: '8',
                name: 'reason_for_services',
                label: 'Reason for Services',
                placeholder: 'Write Reason here',
                errorMessage: errors?.reason_for_services?.message,
                control,
                required: true,
              }}
            />
            <>
              <ReactstrapFileInput
                {...{
                  multiple: true,
                  name: 'ticket_attachments',
                  label: 'Attachment (if have any file max 20 MB)',
                  errorMessage: errors?.ticket_attachments_files?.message,
                  control,
                  required: true,
                }}
              />
              <TicketAttachments
                {...{ attachmentFields, removeAttachment, watch }}
              />
            </>
          </Row>
          <hr />
          <Row>
            <AsyncReactSelect
              {...{
                label: 'Add Assets',
                name: 'asset',
                placeholder: 'Select Assets',
                errorMessage: errors?.assets?.message,
                loadSuggestions: loadAssetsSuggestions,
                control,
                required: true,
              }}
            />
            {assetsFields.length > 0 && (
              <RelevantAssets
                isCreateMode
                removeAsset={removeAsset}
                assetsFields={assetsFields}
              />
            )}
          </Row>
        </div>
        <BillingDetailsCreate
          fields={assetsFields}
          isEditMode
          control={control}
          watch={watch}
          reset={reset}
        />
        <ButtonX
          label={ticketId ? 'Update Ticket' : 'Create Ticket'}
          className='my-3'
          onClick={handleSubmit(onSubmit)}
        />
      </Form>
    </div>
  )
}
