import request from '../utils/request'
// import { encodeQuery } from '../utils/helperFunctions'
import { FETCH_TYPES } from '../utils/constants'
import { REST_API } from './API_Routes'
import ServiceBase from './ServiceBase'

export default class Categories extends ServiceBase {
  create_or_update_category = async (SUBMIT_VALUES) => {
    const formData = new FormData()
    Object.keys(SUBMIT_VALUES).forEach((key) => {
      if (SUBMIT_VALUES[key]) {
        formData.append(key, SUBMIT_VALUES[key])
      }
    })

    const option = {
      method: FETCH_TYPES.POST,
      body: formData,
    }
    const token = true
    const isFormData = true
    const response = await request(
      SUBMIT_VALUES?.id ? REST_API.CATEGORIES_UPDATE : REST_API.CATEGORIES_CREATE,
      option,
      token,
      isFormData,
    )
    return response
  }
}
