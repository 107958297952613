import request from '../utils/request'
// import { encodeQuery } from '../utils/helperFunctions'
import { FETCH_TYPES } from '../utils/constants'
import { REST_API } from './API_Routes'
import ServiceBase from './ServiceBase'

export default class Sites extends ServiceBase {
  create_or_update_site = async (SUBMIT_VALUES) => {
    const formData = new FormData()
    Object.keys(SUBMIT_VALUES).forEach((key) => {
      if (Array.isArray(SUBMIT_VALUES[key])) {
        SUBMIT_VALUES[key].forEach((item) => {
          formData.append(key + '[]', item)
        })
      } else if (SUBMIT_VALUES[key]) {
        formData.append(key, SUBMIT_VALUES[key])
      }
    })

    const option = {
      method: FETCH_TYPES.POST,
      body: formData,
    }
    const token = true
    const isFormData = true
    const response = await request(
      SUBMIT_VALUES?.id ? REST_API.SITES_UPDATE : REST_API.SITES_CREATE,
      option,
      token,
      isFormData,
    )
    return response
  }

  create_or_update_team = async (SUBMIT_VALUES) => {
    const formData = new FormData()
    Object.keys(SUBMIT_VALUES).forEach((key) => {
      if (SUBMIT_VALUES[key]) {
        if (Array.isArray(SUBMIT_VALUES[key])) {
          formData.append(key, JSON.stringify(SUBMIT_VALUES[key]))
        } else {
          formData.append(key, SUBMIT_VALUES[key])
        }
      }
    })

    const option = {
      method: FETCH_TYPES.POST,
      body: formData,
    }
    const token = true
    const isFormData = true
    const response = await request(
      SUBMIT_VALUES?.team_id ? REST_API.TEAM_UPDATE : REST_API.TEAM_CREATE,
      option,
      token,
      isFormData,
    )
    return response
  }

  viewTeam = async (id) => {
    const response = await request(REST_API.TEAM_VIEW + id)
    return response
  }
}
