import React, { useState, useEffect } from 'react'
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  InputGroup,
} from 'react-bootstrap'
import moment from 'moment'
import { CLASSES } from '../../../assets/styles/classes'
import MoreIcon from '../../../assets/icons/common/more.svg'
import EditIcon from '../../../assets/icons/common/edit.svg'
import DeleteIcon from '../../../assets/icons/common/delete.svg'
import PlusIcon from '../../../assets/icons/common/plus-white.svg'
import UserIcon from '../../../assets/icons/sidebar/users-1.svg'
import TicketIcon from '../../../assets/icons/sidebar/ticket.svg'
import DropDownIcon from '../../../assets/icons/common/down-arrow-solid.svg'
import { useDispatch, useSelector } from 'react-redux'
import Table from '../../../components/table/Table'
import { fakeData } from '../../../utils/faker'
import DropDownX from '../../../components/extras/DropDownX'
import SearchFilter from '../../../components/extras/SearchFilter'
import { useNavigate } from 'react-router-dom'
import ActionElements from '../../../components/extras/ActionElements'
import { PAGINATION_TYPES } from '../../../utils/constants'
import { AssetService } from '../../../services';
import getPaginationData from '../../../store/actions/pagination'
import { setCurrentPage } from '../../../store/reducers'
import { addOrUpdatePaginationData } from '../../../store/actions/pagination'
import REST_API from '../../../services/API_Routes'

export default function CustomerAssets() {
  const navigate = useNavigate()
  const columns = [

    {
      name: 'Asset Name',
      minWidth: '180px',
      cell: ({ asset_name }) => asset_name,
    },
    {
      name: 'Brand',
      // minWidth: '180px',
      cell: ({ asset_brand_name }) => asset_brand_name,
    },
    {
      name: 'Model',
      // minWidth: '180px',
      cell: ({ asset_model_no }) => asset_model_no,
    },
    {
      name: 'Quantity',
      // minWidth: '180px',
      cell: ({ asset_qty }) => asset_qty,
    },
    {
      name: 'Price',
      // minWidth: '180px',
      cell: ({ asset_price }) => asset_price,
    },

    {
      name: 'Actions',
      width: '120px',
      style: { paddingRight: '30px' },
      center: true,
      allowOverflow: true,
      cell: (row) => (
        <ActionElements
          id={row.userId || 1}
          editClick={() => navigate(`/assets/edit/${row.asset_id}`)}
          deleteClick={() => deleteClick(row.asset_id)} 
        />
      ),
    },
  ]

  const dispatch = useDispatch()
  const { items, currentPage, rowsPerPage } = useSelector(
    ({ pagination }) => pagination[PAGINATION_TYPES.ASSET],
  )
  const isLoading = useSelector(({ loader }) => loader[PAGINATION_TYPES.ASSET])
  const [searchQuery, setSearchQuery] = useState({})

  useEffect(() => {
    dispatch(
      getPaginationData({
        key: PAGINATION_TYPES.ASSET,
        ServiceName: AssetService,
        page: currentPage,
        per_page: rowsPerPage,
        ...searchQuery,
      }),
    )
  }, [currentPage, rowsPerPage, searchQuery])

  const handleSearch = (query) => {
    setSearchQuery(query)
    dispatch(
      setCurrentPage({
        key: PAGINATION_TYPES.ASSET,
        currentPage: 1,
      }),
    )
  }

  const deleteClick = async (id) => {
    dispatch(
      addOrUpdatePaginationData({
        id,
        key: PAGINATION_TYPES.ASSET,
        ServiceName: AssetService,
        primaryKey: 'asset_id',
        route: REST_API.ASSET_DELETE + id,
        value: { asset_active: 0 },
      }),
    )
  }

  return (
    <Container fluid className='p-4 py-2 mt-2'>
      <div>
        <div className={CLASSES.content_between}>
          <h4 className={CLASSES.items_center}>
            {' '}
            <Image src={TicketIcon} className='me-3' /> All Asset list
          </h4>
          <Button
            onClick={() => navigate('/assets/create')}
            className={CLASSES.items_center + 'bg-blue'}
          >
            <Image src={PlusIcon} className={'me-2'} /> Add New Asset
          </Button>
        </div>

        <div className='mb-5 mt-3 pt-4 border-radius-10 black-shadow-15'>
          <div className={CLASSES.content_between + 'px-4 mb-4'}>
            <div className='px-0 px-md-2'>
              <SearchFilter  handleSearch={handleSearch} placeholder='Asset Name'/>
            </div>

            {/* <div className={CLASSES.content_between + 'px-0 px-md-2'}>
              <DropDownX label='Sort by : ' />
            </div> */}
          </div>

          <Table
            columns={columns}
            data={items}
            progressPending={isLoading}
            paginationType={PAGINATION_TYPES.ASSET}
            customStyle={{
              rows: {
                style: {
                  borderTop: '0px solid #E0E0E0',
                  borderBottom: '1px solid #E0E0E0 !important',
                  display: 'flex !important',
                  alignItems: 'center !important',
                  padding: 15,
                },
              },
            }}

            conditionalRowStyles={[
              {
                when: ({ asset_active }) =>
                  asset_active == 0,
                style: {
                  backgroundColor: '#FFB0B0',
                },
              },
            ]}

          />
        </div>
      </div>
    </Container>
  )
}
