import React from 'react'
import { InputGroup, Image, Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import SearchIcon from '../../assets/icons/common/search.svg'
import { CLASSES, COLORS } from '../../assets/styles/classes'
import { Button } from 'bootstrap'
import ButtonX from '../common/ButtonX'
import ReactstrapInput from '../form/input-fields/ReactstrapInput'

export default function SearchFilter({
  handleSearch = () => {},
  name = 'name',
  label = 'Search',
  placeholder = 'Search',
}) {
  const [isKeyDown, setKeyDown] = React.useState(false)
  const { reset, control, handleSubmit } = useForm({
    mode: 'all',
    defaultValues: {},
  })

  const onSubmit = (values) => {
    handleSearch(values)
  }

  return (
    <div className={CLASSES.items_center}>
      <ReactstrapInput
        control={control}
        noGrid
        placeholder={placeholder}
        showError={false}
        name={name}
      />
      <ButtonX
        onClick={handleSubmit(onSubmit)}
        label={label}
        className='ms-2'
      />
    </div>
  )
}