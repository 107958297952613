import React from 'react'
import DataTable from 'react-data-table-component'
import { ThreeDots } from 'react-loader-spinner'
//
import './style.scss'
import CustomStylesDataTable from './CustomStyleDataTable'
import CustomPagination from './CustomPagination'
import { TABLE_ROWS_PER_PAGE } from '../../utils/constants'
import { COLORS, CLASSES } from '../../assets/styles/styles'

export let paginationRef = React.createRef().current

const Table = ({
  columns,
  data,
  className,
  style,
  customStyle,
  responsive = true,
  pagination = true,
  conditionalRowStyles = () => {},
  rowPerPage = TABLE_ROWS_PER_PAGE,
  paginationType,
  ...rest
}) => {
  // const handlePagination = (page) => {
  //   console.log(page)
  // }
  return (
    <>
      <div style={{ ...style }} className={className}>
        {/* <TableColumnFilter {...{
          columnSearch,
          setColumnSearch,
          columns,
        }}
        /> */}
        <DataTable
          columns={columns}
          className='react-dataTable-custom pb-3'
          data={data}
          highlightOnHover
          paginationPerPage={rowPerPage}
          responsive={responsive}
          pagination={pagination}
          paginationComponent={(props) => {
            paginationRef = props
            return (
              <CustomPagination {...props} paginationType={paginationType}/>
            )
          }}
          progressComponent={
            <ThreeDots
              visible={true}
              height='15'
              width='80'
              color={COLORS.theme}
              radius='9'
              ariaLabel='three-dots-loading'
              wrapperStyle={{}}
              wrapperClass={CLASSES.content_center + 'my-5'}
            />
          }
          customStyles={{
            ...CustomStylesDataTable,
            rows: {
              style: {
                borderTop: '0px solid #E0E0E0',
                borderBottom: '0px !important',
                display: 'flex !important',
                alignItems: 'center !important',
                padding: 15,
              },
            },
            ...customStyle,
          }}
          conditionalRowStyles={conditionalRowStyles}
          {...rest}
        />
      </div>
    </>
  )
}

export default Table