import { combineReducers } from 'redux'
//
import loaderReducer from './loader'
import alertReducer from './alert'
import languageReducer from './language'
import authReducer from './auth'
import navbarReducer from './navbar'
import paginationReducer from './pagination'

const rootReducer = combineReducers({
  auth: authReducer,
  loader: loaderReducer,
  language: languageReducer,
  navbar: navbarReducer,
  alert: alertReducer,
  pagination: paginationReducer,
})

export * from './loader'
export * from './auth'
export * from './language'
export * from './navbar'
export * from './alert'
export * from './pagination'

export default rootReducer // for configure store in store/index.js
