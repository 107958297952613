import React from 'react';
import CardElement from './CardElement';

function CustomerInvoice({ data }) {
  return (
    <div className='w-100'>
      {data.map((invoice, index) => (
        <CardElement key={index} data={invoice} page='invoice' />
      ))}
      {/* Optional: Fallback if no invoices are present */}
      {data.length === 0 && <CardElement data={{ status: 0 }} page='invoice' />}
    </div>
  );
}

export default CustomerInvoice;
