import React from 'react'
import { Button, Card, Col, Image, Row } from 'react-bootstrap'
import DeleteIcon from '../../../assets/icons/common/delete.svg'
import Badge from 'react-bootstrap/Badge'
import { CLASSES, COLORS } from '../../../assets/styles/classes'
import CommunicateModal from './CommunicateModal'
import { FETCH_TYPES } from '../../../utils/constants'
import request from '../../../utils/request'

function CustomerCommunicate({ data = {} }) {
  const [messages, setMessages] = React.useState([])

  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    setMessages(data?.messages)
  }, [])

  const delete_message = async (id) => {
    setMessages((prev) => prev.filter((item) => item.message_id != id))

    setLoading(true)
    const formData = new FormData()
    formData.append('message_id', id)
    const option = {
      method: FETCH_TYPES.POST,
      body: formData,
    }
    const token = true
    const isFormData = true
    const response = await request(
      'ticket/message/delete',
      option,
      token,
      isFormData,
    )
    if (response?.success) {
      // setMessages((prev) => prev.filter((item) => item.message_id != id))
    }
    setLoading(false)
  }
  const TimeElements = ({ element }) => {
    return (
      <div className='d-flex align-items-center justify-content-center '>
        <div style={styles.round} />
        <span style={{ color: '#888888', marginLeft: '10px' }}>{element}</span>
      </div>
    )
  }

  const CardTopElements = ({ time, date, method, id }) => {
    return (
      <div
        style={{
          ...styles.fontSize14,
          display: 'flex',
          alignItems: 'center',
          alignSelf: 'center',
          justifyContent: 'space-evenly',
        }}
      >
        {/* <TimeElements element={time} /> */}
        <TimeElements element={date} />

        <div>
          <Badge
            className='px-3 py-2 text-black'
            style={{ fontSize: '11px', backgroundColor: COLORS.badge }}
            bg={COLORS.badge}
          >
            {(method == 2 && 'Email') ||
              (method == 3 && 'SMS') ||
              (method == 1 && 'Whatsapp')}
          </Badge>
        </div>

        <div>
          <Button
            onClick={() => delete_message(id)}
            variant='white'
            className=' btn-sm'
          >
            <Image width={'20px'} height={'20px'} src={DeleteIcon} />
          </Button>
        </div>
      </div>
    )
  }

  const CommunicateCards = ({ item }) => {
    console.log('item.......', item)
    return (
      <Card className='w-100 shadow-basic border-1 mb-3'>
        <Card.Body className='w-100'>
          <Row>
            <Col xs={12} md={6}>
              <div className='d-flex flex-column justify-content-start align-items-start'>
                <div style={{ ...styles.fontSize14, fontWeight: '500' }}>
                  {data?.ticket?.customer?.customer_first_name +
                    ' ' +
                    data?.ticket?.customer?.customer_last_name +
                    ` (${
                      data?.ticket?.customer?.customer_email_1 ||
                      data?.ticket?.customer?.customer_email_1 ||
                      data?.ticket?.customer?.customer_phone1
                    })`}
                </div>
                <div style={{ fontSize: '11px' }}>
                  {/* <TimeElements element={'Send by Admin ID :568565'} /> */}
                  <TimeElements
                    element={'Send by Admin (You)'}
                    // element={'Send by Admin: ' + item?.admin_name}
                  />
                </div>
              </div>
            </Col>

            <Col xs={12} md={6}>
              {/* <CardTopElements time={'10:00 AM'} date={'25 Feb, 2023'} method={'mail'} id={'1'} /> */}
              <CardTopElements
                date={item.sent_at}
                method={item.message_type}
                id={item.message_id}
              />
            </Col>
          </Row>
        </Card.Body>

        <Card.Body className='pb-4'>{item.message_content}</Card.Body>
      </Card>
    )
  }

  return (
    <>
      <CommunicateModal setMessages={setMessages} data={data} />
      {/* <CommunicateCards data={data} />
            <CommunicateCards data={data} />
            <CommunicateCards data={data} /> */}
        <div style={{ minHeight: '500px', overflow: 'auto' }}>
            {messages?.map((item, index) => (
                <CommunicateCards key={index} item={item} />
            ))}
        </div>
    </>
  )
}
const styles = {
  fontSize14: {
    fontSize: '14px',
  },
  round: {
    borderRadius: '50%',
    height: '5px',
    width: '5px',
    backgroundColor: '#888888',
  },
}

export default CustomerCommunicate
