import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Dropdown, DropdownButton, Button, Col, Container, Image } from 'react-bootstrap'
import { CLASSES } from '../../../assets/styles/classes';
import UserIcon from '../../../assets/icons/sidebar/users-1.svg'
import TabHeader from '../../../components/layout/TabHeader';
import DropDownIcon from '../../../assets/icons/common/down-arrow-solid.svg'
import Row from 'react-bootstrap/Row';

import SearchInput from '../components/SearchInput';
import CharacterCard from '../components/CharacterCard';
import CustomerTabs from '../components/CustomerTabs';
import CustomerInvoice from '../components/CustomerInvoiceTab';
import CustomerCommunicate from '../components/CustomerCommunicateTab';
import  CustomerTicketTab  from '../components/CustomerTicketTab';
import { CustomerService } from '../../../services'
import { setAlert, setLoading } from '../../../store/reducers'
import { REST_API } from '../../../services/API_Routes'
import { LOADING_NAME } from '../../../utils/constants'

export default function CustomerDetails() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation();
  const id = location.state?.id;
  const [isLoading, setIsLoading] = React.useState(false)
  const [data, setData] = React.useState({})
  const [tabName, setTabName] = React.useState('Ticket');

  const onTabChange = (tabName) => {
    setTabName(tabName);
  }
  // replace with api data
  const dummyData = [
    {
      id: 1,
    },
    {
      id: 2,
    },
  ]

  const getData = async () => {
    dispatch(setLoading({ key: LOADING_NAME.COMMON, value: true }))
    try {
      const response = await CustomerService.view({
        route: REST_API.CUSTOMER_VIEW + id,
      })
      if (response.success) {
        setData(response.data);
      }
    } catch (error) {
    } finally {
      dispatch(setLoading({ key: LOADING_NAME.COMMON, value: false }))
    }
  }

  React.useEffect(() => {
    if (id) {
      getData()
    }
  }, [id])



  const DropDownElement = () => {
    return null
    return (
      <Dropdown style={{ border: '1px solid #ccc', borderRadius: '8px' }}>
        <Dropdown.Toggle as={DropdownButton} variant="white" title={
          <>
            <span style={{ marginRight: '10px' }}>+ New</span>
            <Image src={DropDownIcon} className="pointer " />
          </>
        }>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item eventKey={'1'}>New Ticket</Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item eventKey={'2'}>Appointment</Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item eventKey={'3'}>Reminder</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  }


  return (
    <Container fluid className='p-4 py-2 mt-2'>
      <TabHeader Img={<Image src={UserIcon} className='me-3' />} title={"Customer Details"} element={<DropDownElement />} />
      <div className='my-5'> </div>
      <Row>
        <Col xs={12} md={4} lg={4}>
          <CharacterCard data={data} 
            />
        </Col>

        <Col xs={12} md={8} lg={8}>
          <Row>
            <Col xs={12} md={8} lg={9}>
              <CustomerTabs onTabChange={onTabChange} />
            </Col>
            <Col xs={12} md={4} lg={3} className="order-first order-md-last order-lg-last">
              <SearchInput />
            </Col>
          </Row>

          <hr className='text-gray mb-3' />

          <Row>
            <Col>
              {tabName === 'Ticket' && <CustomerTicketTab data={data?.tickets} />}
              {tabName === 'Invoice' && (<CustomerInvoice data={data?.tickets} />)}
              {/* {tabName === 'Communicate' && (<CustomerCommunicate data={data?.communications } />)} */}
            </Col>
          </Row>
        </Col>
      </Row>


    </Container>
  )
}
