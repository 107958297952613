import { createSlice } from '@reduxjs/toolkit';
import { LOADING_NAME } from '../../utils/constants';

export const loader = createSlice({
  name: 'loader',
  initialState: {
    [LOADING_NAME.COMMON]: false,
  },
  reducers: {
    setLoading: (state, { payload }) => ({
      ...state,
      [payload.key || LOADING_NAME.COMMON]: payload.value,
    }),
  },
});

export const { setLoading } = loader.actions;
export default loader.reducer;
