import { createSlice } from '@reduxjs/toolkit';

export const navbar = createSlice({
  name: 'NAVBAR',
  initialState: {
    title: 'Projects',
  },
  reducers: {
    setNavbarTitle: (state, { payload }) => ({
      ...state,
      title: payload,
    }),
  },
});

export const { setNavbarTitle } = navbar.actions;
export default navbar.reducer;
