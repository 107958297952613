import request from '../utils/request'
// import { encodeQuery } from '../utils/helperFunctions'
import { FETCH_TYPES } from '../utils/constants'
import { REST_API } from './API_Routes'
import ServiceBase from './ServiceBase'

export default class Tickets extends ServiceBase {
  create_or_update_ticket = async (SUBMIT_VALUES) => {
    const formData = new FormData()
    Object.keys(SUBMIT_VALUES).forEach((key) => {
      if (SUBMIT_VALUES[key]) {
        if (Array.isArray(SUBMIT_VALUES[key]) && key == 'ticket_attachments') {
          SUBMIT_VALUES[key].forEach((item) => {
            formData.append(key + '[]', item)
          })
        } else if (typeof SUBMIT_VALUES[key] == 'object') {
          formData.append(key, JSON.stringify(SUBMIT_VALUES[key]))
        } else {
          formData.append(key, SUBMIT_VALUES[key])
        }
      }
    })

    const option = {
      method: FETCH_TYPES.POST,
      body: formData,
    }
    const token = true
    const isFormData = true
    const response = await request(
      SUBMIT_VALUES.id ? REST_API.TICKET_UPDATE : REST_API.TICKET_CREATE,
      option,
      token,
      isFormData,
    )
    return response
  }
}
