import React from 'react'
import { FormGroup, Col, FormLabel, FormControl } from 'react-bootstrap'
import { Controller } from 'react-hook-form'
//
import LabelErrors from '../LabelErrors'
import { CLASSES } from '../../../assets/styles/styles'

const ReactstrapFileInput = ({
  label = '',
  disabled = false,
  required = false,
  name = '',
  placeholder = '',
  errorMessage = '',
  style = {},
  className = '',
  noGrid = false,
  showError = true,
  accept = 'image/*',
  multiple = false,
  rightComponent = null,
  control = {},
  colClass = '',
  formGroupClass = 'mb-0',
  xxl = '4',
  xl = '4',
  lg = '4',
  md = '6',
  sm = '12',
  xs = '12',
}) => {
  const renderInput = () => (
    <>
      {label && (
        <div className={CLASSES.content_between}>
          <FormLabel for={name}>
            {label} {required && <span className='text-danger'>*</span>}
          </FormLabel>
          {rightComponent}
        </div>
      )}
      <Controller
        name={name}
        render={({ field: { onChange } }) => (
          <FormControl
            type={'file'}
            style={style}
            onChange={(event) => {
              event.preventDefault()
              event.stopPropagation()
              if (!multiple) {
                onChange(event.target.files[0])
              } else {
                onChange(event.target.files)
              }
            }}
            multiple={multiple}
            accept={accept}
            disabled={disabled}
            placeholder={placeholder}
            className={className}
          />
        )}
        control={control}
      />
      {showError && <LabelErrors name={name} errorMessage={errorMessage} />}
    </>
  )

  return (
    <>
      {noGrid ? (
        <FormGroup className={formGroupClass}>{renderInput()}</FormGroup>
      ) : (
        <>
          <Col
            className={colClass}
            xs={xs}
            sm={sm}
            md={md}
            lg={lg}
            xl={xl}
            xxl={xxl}
          >
            <FormGroup className={formGroupClass}>{renderInput()}</FormGroup>
          </Col>
        </>
      )}
    </>
  )
}

export default ReactstrapFileInput
