import { useMediaQuery } from 'react-responsive'
import * as React from 'react'
import { useReactToPrint } from 'react-to-print'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import moment from 'moment'

const MySwal = withReactContent(Swal)

export const useScreenSizes = () => {
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

  return {
    isDesktopOrLaptop,
    isBigScreen,
    isTabletOrMobile,
    isPortrait,
    isRetina,
  }
}

export const preConfirmAlert = async ({
  // title = 'Submit updated data?',
  // text = "You won't be able to revert this!",
  // confirmButtonText = 'Save and Submit',
  // denyButtonText = 'Submit',
  // confirmButton = 'btn btn-primary',
  // denyButton = 'btn btn-primary ml-1',
  title = 'Submit updated data?',
  text = "You won't be able to revert this!",
  confirmButtonText = 'Save and Submit',
  denyButtonText = 'Submit',
  confirmButton = 'btn btn-primary',
  denyButton = 'btn btn-primary ml-1',
  ...rest
}) => {
  const result = await MySwal.fire({
    title,
    text,
    icon: 'warning',
    showDenyButton: true,
    confirmButtonText,
    denyButtonText,
    customClass: {
      confirmButton,
      denyButton,
    },
    buttonsStyling: false,
    showLoaderOnConfirm: true,
    allowOutsideClick: true,
    ...rest,
  })
  return result
}

export const encodeQuery = (data) => {
  let query = '?'
  for (const d in data) {
    if (data[d]) {
      query += `${encodeURIComponent(d)}=${encodeURIComponent(data[d])}&`
    }
  }
  return query.slice(0, -1)
}

const DATE_FORMAT = 'DD-MM-YYYY'
const TIME_FORMAT = 'HH:mm'
export const setTimeFormat = (time) => time.format('HH:mm:ss')
export const getTimeFormat = (time) => moment(new Date(time), TIME_FORMAT)
export const viewTimeFormat = (time) => moment(time).format(TIME_FORMAT)

export const setDateFormat = (date) => date.format(DATE_FORMAT)
export const getDateFormat = (date) => moment(new Date(date), DATE_FORMAT)
export const viewDateFormat = (date, format = DATE_FORMAT) => {
  if (date) {
    return moment(date).format(format)
  }
  return 'N/A'
}

export const emailValidationRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
