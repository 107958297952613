import React from 'react'
import { Image, Stack } from 'react-bootstrap'
import './sidebar.css'

import Logo from '../../assets/logo/logo.svg'
import GridIcon from '../../assets/icons/sidebar/grid.svg'
import User1Icon from '../../assets/icons/sidebar/users-1.svg'
import User2Icon from '../../assets/icons/sidebar/user-2.svg'
import TicketIcon from '../../assets/icons/sidebar/ticket.svg'
import FileIcon from '../../assets/icons/sidebar/file.svg'
import PuzzleIcon from '../../assets/icons/sidebar/puzzle-piece.svg'
import CalendarIcon from '../../assets/icons/sidebar/calendar-minus.svg'
import SettingsIcon from '../../assets/icons/sidebar/settings.svg'
import HelpIcon from '../../assets/icons/sidebar/help-circle.svg'
import AlertIcon from '../../assets/icons/sidebar/alert-triangle.svg'
import LogoutIcon from '../../assets/icons/sidebar/log-out.svg'

import GridWhiteIcon from '../../assets/icons/sidebar/grid-white.svg'
import User1WhiteIcon from '../../assets/icons/sidebar/users-1-white.svg'
import User2WhiteIcon from '../../assets/icons/sidebar/user-2-white.svg'
import TicketWhiteIcon from '../../assets/icons/sidebar/ticket-white.svg'
import FileWhiteIcon from '../../assets/icons/sidebar/file-white.svg'
import PuzzleWhiteIcon from '../../assets/icons/sidebar/puzzle-piece-white.svg'
import CalendarWhiteIcon from '../../assets/icons/sidebar/calendar-minus-white.svg'
import SettingsWhiteIcon from '../../assets/icons/sidebar/settings-white.svg'
import HelpWhiteIcon from '../../assets/icons/sidebar/help-circle-white.svg'
import AlertWhiteIcon from '../../assets/icons/sidebar/alert-triangle-white.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Logout from './Logout'

export default function Sidebar() {
  const [isActive, setIsActive] = React.useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className='sidebar black-shadow-10 sidebar-block-remove '>
      <Image src={Logo} className='p-4' />
      <Stack gap={2}>
        {[
          { name: 'Overview', icon:  { white: GridWhiteIcon, black: GridIcon}, route: '/' },
          { name: 'Customer', icon: { white: User1WhiteIcon, black: User1Icon}, route: '/customer' },
          { name: 'Ticket', icon: { white: TicketWhiteIcon, black: TicketIcon}, route: '/ticket' },
          { name: 'Invoice', icon: { white: FileWhiteIcon, black: FileIcon}, route: '/Invoice' },
          { name: 'Assets', icon: { white: PuzzleWhiteIcon, black: PuzzleIcon}, route: '/assets' },
          // { name: 'Admin', icon: { white: User2WhiteIcon, black: User2Icon}, route: '/admin' },
          { name: 'Appointment', icon: { white: CalendarWhiteIcon, black: CalendarIcon}, route: '/appointments' },
          { name: 'Settings', icon: { white: SettingsWhiteIcon, black: SettingsIcon}, route: '/Settings' },
        ].map((item, i) => (
          <Link
            onClick={() => setIsActive(i)}
            to={item.route}
            key={i}
            className='p-2 px-4 item d-flex align-items-center'
            style={{ backgroundColor: ( location.pathname === item.route) ? '#2c99e3' : '', color: ( location.pathname === item.route) ? 'white' : 'initial'}}
          >
            <Image src={( location.pathname === item.route) ? item.icon.white : item.icon.black} className='me-2 svg-icon' />
            <span>{item.name}</span>
          </Link>
        ))}

        <div className='mt-5'>
          {[
            { name: 'Help', icon: HelpIcon },
            { name: 'Support', icon: AlertIcon },
          ].map((item, i) => (
            <div key={i} className='p-2 px-4 item d-flex align-items-center'>
              <Image src={item.icon} className='me-2 svg-icon' />
              <span>{item.name}</span>
            </div>
          ))}

            {/* <div className='p-2 px-4 item d-flex align-items-center'>
              <Image src={LogoutIcon} className='me-2 svg-icon' />
              <span className='text-red'>Sign Out</span>
            </div> */}
            <Logout />
        </div>
      </Stack>
    </div>
  )
}
