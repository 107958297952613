import React, { Fragment, lazy, Suspense } from 'react'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom'
import Home from '../pages/home/Home'
// import Header from '../components/header'
import PageNotFound from '../pages/no-page/PageNotFound'
// import Sidebar from '../components/sidebar/Sidebar'
import CustomerRoutes from './CustomerRoutes'
import TicketsRoutes from './TicketsRoutes'
import AdminRoutes from './AdminRoutes'
import CustomerAssetsRoutes from './CustomerAssetsRoutes'
// import ScrollToTop from '../components/extras/ScrollToTop'
import InvoiceRoutes from './InvoiceRoutes'
import AppointmentRoutes from './AppointmentRoutes'
import AuthRoutesWrapper from '../components/layout/AuthRoutesWrapper'
import MainLayout from '../components/layout/MainLayout'
import { useSelector } from 'react-redux'

const Login = lazy(() => import('../pages/auth/Login'))

const ROUTES = [
  ...CustomerRoutes,
  ...TicketsRoutes,
  ...AdminRoutes,
  ...CustomerAssetsRoutes,
  ...InvoiceRoutes,
  ...AppointmentRoutes,
]

const routesFromElements = (user_type_id) =>
  createRoutesFromElements(
    <Fragment>
      <Route element={<AuthRoutesWrapper />}>
        <Route path='/login' Component={Login} />
        {/* <Route path='/forgot-password' Component={ForgotPassword} /> */}
      </Route>
      <Route path='/' element={<MainLayout />}>
        <Route index Component={Home} />
        {ROUTES.map((item, i) => (
          <Route key={i} path={item.path} Component={item.component} />
        ))}
        {/* {user_type_id &&
          ROUTES.map(
            (item, i) =>
              item?.permissions?.includes(user_type_id) && (
                <Route key={i} path={item.path} Component={item.component} />
              ),
          )} */}
      </Route>
      <Route path='/*' Component={PageNotFound} />
    </Fragment>,
  )

const router = (user_type_id) =>
  createBrowserRouter(routesFromElements(user_type_id))

const RoutersList = () => {
  const { user_type_id } = useSelector((state) => state.auth)
  return (
    <div className='app-body'>
      <Suspense fallback={<div className='loader'></div>}>
        <div className='d-flex vh-100'>
          <RouterProvider router={router(user_type_id)} />
        </div>
      </Suspense>
    </div>
  )
}

export default RoutersList
