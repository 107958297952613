import React from 'react'
import { Button, Dropdown, Image } from 'react-bootstrap'
import EditIcon from '../../assets/icons/common/edit.svg'
import DeleteIcon from '../../assets/icons/common/delete.svg'
import MoreIcon from '../../assets/icons/common/more.svg'
import EyeIcon from '../../assets/icons/common/eye.svg'
import { CLASSES } from '../../assets/styles/classes'
import { preConfirmAlert } from '../../utils/helperFunctions'

const ActionElements = ({
  id,
  editClick,
  deleteClick,
  viewClick,
  dropdownItems,
}) => {
  // more customization will be added later - under development

  const handleEditClick = () => {
    editClick(id)
  }
  const handleDeleteClick = async () => {
    deleteClick(id);
    // const { dismiss, isConfirmed, isDenied, isDismissed } =
    //   await preConfirmAlert({
    //     title: 'Are you sure to delete?',
    //     text: "You won't be able to revert this!",
    //     confirmButtonText: 'Yes',
    //     denyButtonText: 'No',
    //     confirmButton: 'btn btn-danger',
    //     denyButton: 'btn btn-primary ms-3',
    //   })
    // if (isConfirmed) {
    //   deleteClick(id)
    // }
  }
  const handleViewClick = () => {
    viewClick(true)
  }

  function DropDownLinks() {
    return dropdownItems ? (
      <Dropdown>
        <Dropdown.Toggle variant='white' id='dropdown-basic'>
          <Image width={'16px'} height={'16px'} src={MoreIcon} />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {/* <Dropdown.Item href="#/action-1">Details</Dropdown.Item> */}
          {dropdownItems}
        </Dropdown.Menu>
      </Dropdown>
    ) : null
  }

  return (
    <div className={CLASSES.content_between + 'me-3'}>
      <Button onClick={handleEditClick} variant='white'>
        <Image width={'20px'} height={'20px'} src={EditIcon} />
      </Button>
      <Button onClick={handleDeleteClick} variant='white'>
        <Image width={'20px'} height={'20px'} src={DeleteIcon} />
      </Button>
      {viewClick ? (
        <Button onClick={handleViewClick} variant='white'>
          <Image width={'20px'} height={'20px'} src={EyeIcon} />
        </Button>
      ) : (
        <DropDownLinks />
      )}
    </div>
  )
}

export default ActionElements
