import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import HeaderInfo from '../../../components/extras/HeaderInfo';
import FileIcon from '../../../assets/icons/sidebar/file.svg';
import PlusIcon from '../../../assets/icons/common/plus-white.svg';
import { CLASSES } from '../../../assets/styles/classes';
import { useNavigate } from 'react-router-dom';
import SearchFilter from '../../../components/extras/SearchFilter';
import DropDownX from '../../../components/extras/DropDownX';
import CardElement from '../components/CardElement';
import { useDispatch, useSelector } from 'react-redux';
import getPaginationData from '../../../store/actions/pagination';
import { InvoiceService } from '../../../services';
import { setCurrentPage } from '../../../store/reducers';
import { addOrUpdatePaginationData } from '../../../store/actions/pagination';
import REST_API from '../../../services/API_Routes';
import CustomPagination from '../../../components/table/CustomPagination';
import { PAGINATION_TYPES } from '../../../utils/constants';
import { setAlert, userSignOut } from '../../../store/reducers';

export default function Invoice() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { items, currentPage, rowsPerPage } = useSelector(
        ({ pagination }) => pagination[PAGINATION_TYPES.INVOICES]
    );
    const isLoading = useSelector(({ loader }) => loader[PAGINATION_TYPES.INVOICES]);
    const [searchQuery, setSearchQuery] = useState({});

    useEffect(() => {
        dispatch(
            getPaginationData({
                key: PAGINATION_TYPES.INVOICES,
                ServiceName: InvoiceService,
                page: currentPage,
                per_page: rowsPerPage,
                ...searchQuery,
            })
        );
    }, [currentPage, rowsPerPage, searchQuery]);

    const handleSearch = (query) => {
        setSearchQuery(query);
        dispatch(
            setCurrentPage({
                key: PAGINATION_TYPES.INVOICES,
                currentPage: 1,
            })
        );
    };

    const InvoiceCard = () => {
        return (
            <>
                {items.map((data) => (
                    <CardElement
                        key={data.id}
                        icon={FileIcon}
                        data={data}
                        page="invoice"
                    />
                ))}
            </>
        );
    };

    return (
        <Container fluid className='p-4 py-2 mt-2'>
            <div>
                <HeaderInfo Img={FileIcon} title="Invoices" element={`Total Invoice: ${items.length}`} />
            </div>

            <div className="mb-5 mt-3 pt-4">
                <Row className='mb-4'>
                    {/* <Col>
                        <SearchFilter handleSearch={handleSearch} />
                    </Col> */}
                    <Col lg={6}></Col>
                    {/* <Col>
                        <div className={CLASSES.content_end}>
                            <DropDownX label='Issue:' />
                            <DropDownX label='Sort by:' />
                        </div>
                    </Col> */}
                </Row>

                <InvoiceCard />

                {isLoading && <div>Loading...</div>} {/* Loading state */}
                {!isLoading && items.length === 0 && <div>No invoices found.</div>} {/* No data state */}
                {/* <CustomPagination paginationType={PAGINATION_TYPES.INVOICES} /> */}
            </div>
        </Container>
    );
}
