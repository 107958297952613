import request from '../utils/request'
import { encodeQuery } from '../utils/helperFunctions'
import { FETCH_TYPES } from '../utils/constants'

class ServiceBase {
  constructor(url) {
    this.API_ENDPOINT = url
  }

  get = async (query) => {
    const queryParam = encodeQuery(query) 
    const response = await request(this.API_ENDPOINT + queryParam, {
      method: FETCH_TYPES.GET,
    })
    return response
  }

  view = async ({route, ...query}) => {
    const queryParam = encodeQuery(query)
    const response = await request(route + queryParam, {
      method: FETCH_TYPES.GET,
    })
    return response
  }

  // exportDownload = async ({route, ...query}) => {
  //   const queryParam = encodeQuery(query)
  //   const response = await request(route + queryParam, {
  //     method: FETCH_TYPES.GET,
  //   })
  //   return response
  // }

  search = async ({ page, per_page, ...query }) => {
    const option = {
      method: FETCH_TYPES.POST,
      body: query,
    }
    const queryParam = encodeQuery({ page, per_page })
    const response = await request(this.API_ENDPOINT + queryParam, option)
    return response
  }

  async update({ route, ...body }) {
    const options = { method: FETCH_TYPES.POST, body }
    const response = await request(route, options)
    return response
  }

  async remove({ route, ...body }) {
    const options = { method: FETCH_TYPES.POST, body }
    const response = await request(route, options)
    return response
  }

  getStates = async (route) => {
    const response = await request(route, {
      method: FETCH_TYPES.GET,
    })
    return response
  }

  getCities = async (route, stateID) => {
    const response = await request(route + stateID, {
      method: FETCH_TYPES.GET,
    })
    return response
  }

}


export default ServiceBase
