import React from 'react'
import ButtonWithIcon from '../../../components/common/ButtonWithIcon'
import FileIcon from '../../../assets/icons/common/download.svg'
// import DownloadIcon from '../../../assets/icons/common/download.svg'
import { CLASSES } from '../../../assets/styles/styles'
// import { Image } from 'react-bootstrap'

export default function FilesDownload({ files = [] }) {
  const downloadImage = (imageUrl, fileName) => {
    // const imageUrl = 'https://example.com/path/to/your/image.jpg'; // Replace with your image URL
    // const fileName = 'downloaded-image.jpg'; // Set the desired file name

    // Create a hidden <a> element
    const link = document.createElement('a');
    link.href = imageUrl;
    link.target = '_blank';
    link.download = fileName; // Set the file name for download

    // Append the link to the document body
    document.body.appendChild(link);

    // Programmatically trigger the download by clicking the link
    link.click();

    // Clean up by removing the link after download
    document.body.removeChild(link);
  };
  return (
    <div className={'d-flex flex-column align-items-start'}>
      {files.map((item, i) => (
        <div key={i} className={CLASSES.items_center + 'mb-3'}>
          <ButtonWithIcon
            icon={FileIcon}
            // label={item?.doc_name}
            label={'Attachment - ' + (i + 1)}
            color='white'
            variant='secondary'
            textColor='black'
            className={'mr-0 border-gray mx-2 ' + CLASSES.content_center}
            // href={item?.url}
            // target='_blank'
            // download={item?.doc_name}
            onClick={() => downloadImage(item?.url, item?.doc_name || 'Attachment - ' + (i + 1))}
            download
          />
          {/* <Image src={DownloadIcon} /> */}
        </div>
      ))}
      {files.length == 0 && <h6 className='text-muted'>No Documents</h6>}
    </div>
  )
}
