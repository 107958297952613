import { FormLabel, FormControl, FormGroup, Col } from 'react-bootstrap'
import { Controller } from 'react-hook-form'
//
import LabelErrors from '../LabelErrors'

const ReactstrapInput = ({
  control = {},
  name = '',
  label = undefined,
  type = 'text',
  as = undefined,
  min = 0,
  required = false,
  defaultValue = undefined,
  rows = undefined,
  disabled = false,
  placeholder = undefined,
  errorMessage = undefined,
  noGrid = false,
  showError = true,
  colClass = '',
  formGroupClass = 'mb-0',
  xxl = '4',
  xl = '4',
  lg = '4',
  md = '6',
  sm = '12',
  xs = '12',
  ...rest
}) => {
  const renderInput = () => (
    <>
      {label && (
        <FormLabel for={name}>
          {label} {required && <span className='text-danger'>*</span>}
        </FormLabel>
      )}
      <Controller
        name={name}
        defaultValue={defaultValue}
        render={({ field }) => (
          <>
            <div className='d-flex align-items-center w-100'>
              <FormControl
                {...field}
                type={type}
                min={min}
                as={as}
                disabled={disabled}
                placeholder={placeholder}
                rows={rows}
                isInvalid={errorMessage}
                className={`${!field?.value ? '' : 'bz-pr-28'} ${
                  errorMessage ? 'border-red' : ''
                }`}
                ref={undefined}
                innerRef={field?.ref}
                onKeyDown={(e) => {
                  if (type === 'number') {
                    return (
                      ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
                    )
                  }
                  return e
                }}
                onPaste={(e) => {
                  if (type === 'number') {
                    const paste = (
                      e.clipboardData || window.clipboardData
                    ).getData('text')
                    if (isNaN(paste)) {
                      return e.preventDefault()
                    }
                  }
                  return e
                }}
                {...rest}
              />
              {/* {field?.value && !disabled ? (
                <div className='position-relative'>
                  <button
                    type='button'
                    onClick={() => {
                      // eslint-disable-next-line no-underscore-dangle
                      // control._fields[name]?._f.ref.focus() // This doesn't work, gives error !!!!!!!!
                      field.onChange('')
                    }}
                    style={{ left: -27, top: -14 }}
                    className='btn close position-absolute'
                    aria-label='Close'
                  >
                    <span aria-hidden='true'>&times;</span>
                  </button>
                </div>
              ) : null} */}
            </div>
          </>
        )}
        control={control}
      />
      {showError && <LabelErrors name={name} errorMessage={errorMessage} />}
    </>
  )

  return (
    <>
      {noGrid ? (
        <FormGroup className={formGroupClass}>{renderInput()}</FormGroup>
      ) : (
        <>
          <Col
            className={colClass}
            xs={xs}
            sm={sm}
            md={md}
            lg={lg}
            xl={xl}
            xxl={xxl}
          >
            <FormGroup className={formGroupClass}>{renderInput()}</FormGroup>
          </Col>
        </>
      )}
    </>
  )
}

export default ReactstrapInput
