import React from 'react'
import ReactApexChart from 'react-apexcharts'
const state = {
  series: (data, name) => [
    {
      name: name || 'Servings',
      data: data || [44, 55, 41, 67, 22, 43, 21, 33, 45, 31, 87, 65],
    },
  ],
  options: {
    annotations: {
      points: [
        {
          x: 'Bananas',
          seriesIndex: 0,
          label: {
            borderColor: '#775DD0',
            offsetY: 0,
            style: {
              color: '#fff',
              background: '#775DD0',
            },
            text: 'Bananas are good',
          },
        },
      ],
    },
    chart: {
      height: 350,
      type: 'bar',
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        horizontal: false,
        borderRadiusApplication: 'last',
        columnWidth: '50%',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 0,
    },

    grid: {
      show: false,
      row: {
        // colors: ['#fff', '#f2f2f2']
      },
    },
    xaxis: {
      labels: {
        rotate: -45,
      },
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
      tickPlacement: 'on',
    },
    yaxis: {
      title: {
        // text: 'Servings',
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        type: 'vertical',
        shadeIntensity: 0.25,
        gradientToColors: ['#80C1E4', '#0071BC'],
        inverseColors: true,
          // opacityFrom: 1,
          // opacityTo: 1,
        stops: [0,  100],
      },
    },
  },
}

export default function BarChart({data, name}) {
  return (
    <div id='chart' className='mt-2'>
      <ReactApexChart
        options={state.options}
        series={state.series(data, name)}
        type='bar'
        height={370}
        // width='25%'
      />
    </div>
  )
}
