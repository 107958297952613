import React from 'react'
import { Form, FormControl, FormGroup, Col } from 'react-bootstrap'
import { Controller } from 'react-hook-form'
import AsyncCreatableSelect from 'react-select/async-creatable'
import _ from 'lodash'
import LabelErrors from '../LabelErrors'
import { COLORS } from '../../../assets/styles/styles'
//

const Input = (props) => {
  if (props.isNumber) {
    return (
      <FormControl
        {...props}
        type='number'
        // onKeyDown={blockInvalidChar}
        onKeyPress={(e) => {
          const iKeyCode = e.which ? e.which : e.keyCode
          if (
            iKeyCode !== 46 &&
            iKeyCode > 31 &&
            (iKeyCode < 48 || iKeyCode > 57)
          ) {
            return e.preventDefault()
          }
          return e
        }}
        onPaste={(e) => {
          const paste = (e.clipboardData || window.clipboardData).getData(
            'text',
          )
          if (isNaN(paste)) {
            return e.preventDefault()
          }
          return e
        }}
      />
    )
  }
  return <FormControl {...props} />
}

const AsyncReactSelect = ({
  label = '',
  name = '',
  required = false,
  showError = true,
  loadSuggestions = () => {},
  defaultValue = undefined,
  isClearable = false,
  isSearchable = true,
  placeholder = '',
  errorMessage = '',
  noGrid = false,
  control = {},
  isDisabled = false,
  isMulti = false,
  cacheOptions = true,
  isNumber = false,
  onHandleChange = undefined,
  colClass = '',
  formGroupClass = '',
  xxl = '4',
  xl = '4',
  lg = '4',
  md = '6',
  sm = '12',
  xs = '12',
  defaultOptions = [],
}) => {
  const wait = 500
  const [_defaultOptions, setDefaultOptions] = React.useState(defaultOptions)

  React.useEffect(() => {
    loadSuggestions('', setDefaultOptions)
  }, [])

  const promiseOptions = _.debounce(loadSuggestions, wait, { leading: true })

  const renderInput = () => (
    <>
      {label && <Form.Label for={name}>{label} {required && <span className='text-danger'>*</span>}</Form.Label>}
      <Controller
        name={name}
        defaultValue={defaultValue}
        render={({ field }) => (
          <AsyncCreatableSelect
            {...field}
            isClearable={isClearable}
            components={{
              IndicatorSeparator: () => null,
              // DropdownIndicator: () => null,
              // Input: (props) => <Input {...props} isNumber={isNumber} />,
            }}
            cacheOptions={cacheOptions}
            defaultOptions={_defaultOptions}
            isSearchable={isSearchable}
            isMulti={isMulti}
            theme={'white'}
            loadOptions={promiseOptions}
            className='' //form-control
            // menuIsOpen
            classNamePrefix='select'
            placeholder={placeholder || 'Type and select'}
            isDisabled={isDisabled}
            isOptionDisabled={(option) => option.disabled}
            closeMenuOnScroll={(e) => {
              const className = e?.target?.className?.substring(0, 17)
              const scrollItemClass = 'select__menu-list'
              if (className === scrollItemClass) {
                return false
              }
              return true
            }}
            menuPosition='fixed'
            isValidNewOption={() => false}
            {...(onHandleChange && {
              onChange: (value) => onHandleChange(value),
            })}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                boxShadow: state.isFocused
                  ? '0 0 0 .25rem rgba(13,110,253,.25)'
                  : baseStyles.boxShadow,
                '&:hover': {
                  // borderColor: COLORS.theme,
                  borderWidth: 1,
                },
                borderColor: state.isFocused
                  ? COLORS.theme
                  : baseStyles.borderColor,
                borderWidth: 1,
                borderRadius: 'var(--bs-border-radius)',
              }),
              placeholder: (baseStyles) => ({
                ...baseStyles,
                textOverflow: 'ellipsis',
                textWrap: 'nowrap',
                overflow: 'hidden',
              }),
              option: (baseStyles, state) => ({
                ...baseStyles,
                // backgroundColor: COLORS.theme,
                // color: 'white',
                // '&:active': {
                //   backgroundColor: COLORS.theme + '90',
                // },
                // '&:hover': {
                //   backgroundColor: COLORS.theme + '99',
                // },
                borderTop: '1px solid white',
                borderRight: '1px solid white',
                borderLeft: '1px solid white',
              }),
              // indicatorsContainer: (baseStyles) => ({
              //   ...baseStyles,
              //   // height: 10,
              //   // width: 10,
              //   // display: 'none',
              // })
            }}
          />
        )}
        control={control}
      />
      {showError && <LabelErrors name={name} errorMessage={errorMessage} />}
    </>
  )
  return (
    <>
      {noGrid ? (
        <FormGroup className={formGroupClass}>{renderInput()}</FormGroup>
      ) : (
        <>
          <Col
            className={colClass}
            xs={xs}
            sm={sm}
            md={md}
            lg={lg}
            xl={xl}
            xxl={xxl}
          >
            <FormGroup className={formGroupClass}>{renderInput()}</FormGroup>
          </Col>
        </>
      )}
    </>
  )
}

export default AsyncReactSelect
