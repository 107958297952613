import { FormGroup, Col, Form, FormLabel } from 'react-bootstrap'
import { Controller } from 'react-hook-form'
import Select from 'react-select'

//
import LabelErrors from '../LabelErrors'
import { COLORS } from '../../../assets/styles/styles'

const ReactSelect = ({
  label = '',
  required = false,
  name = '',
  defaultValue = undefined,
  options = [
    {
      label: 'One',
      value: '1',
    },
    {
      label: 'Two',
      value: '2',
    },
    {
      label: 'Three',
      value: '3',
    },
  ],
  control = {},
  onChange = () => {},
  isDisabled = false,
  placeholder = '',
  errorMessage = '',
  noGrid = false,
  showError = true,
  isClearable = false,
  colClass = '',
  formGroupClass = '',
  xxl = '4',
  xl = '4',
  lg = '4',
  md = '6',
  sm = '12',
  xs = '12',
}) => {
  const renderInput = () => (
    <>
      {label && (
        <FormLabel for={name}>
          {label} {required && <span className='text-danger'>*</span>}
        </FormLabel>
      )}
      <Controller
        name={name}
        defaultValue={defaultValue}
        render={({ field }) => (
          <Select
            {...field}
            isClearable={isClearable}
            placeholder={placeholder || 'Select'}
            isDisabled={isDisabled}
            aria-label='Default select example'
            classNamePrefix='select'
            options={options}
            isSearchable={false}
            // onChange = {onChange}
            components={{
              IndicatorSeparator: () => null,
              // DropdownIndicator: () => null,
              // Input: (props) => <Input {...props} isNumber={isNumber} />,
            }}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                boxShadow: state.isFocused
                  ? '0 0 0 .25rem rgba(13,110,253,.25)'
                  : baseStyles.boxShadow,
                '&:hover': {
                  // borderColor: COLORS.theme,
                  borderWidth: 1,
                },
                borderColor: state.isFocused
                  ? COLORS.theme
                  : baseStyles.borderColor,
                borderWidth: 1,
                borderRadius: 'var(--bs-border-radius)',
              }),
              placeholder: (baseStyles) => ({
                ...baseStyles,
                textOverflow: 'ellipsis',
                textWrap: 'nowrap',
                overflow: 'hidden',
              }),
              option: (baseStyles, state) => ({
                ...baseStyles,
                // backgroundColor: state.isSelected ? COLORS.theme : baseStyles.backgroundColor,
                // color: 'white',
                // '&:active': {
                //   backgroundColor: COLORS.theme + '90',
                // },
                // '&:hover': {
                //   backgroundColor: COLORS.theme + '99',
                // },
                borderTop: '1px solid white',
                borderRight: '1px solid white',
                borderLeft: '1px solid white',
              }),
              // indicatorsContainer: (baseStyles) => ({
              //   ...baseStyles,
              //   // height: 10,
              //   // width: 10,
              //   // display: 'none',
              // })
            }}
          />
        )}
        control={control}
      />
      {showError && <LabelErrors name={name} errorMessage={errorMessage} />}
    </>
  )

  return (
    <>
      {noGrid ? (
        <FormGroup className={formGroupClass}>{renderInput()}</FormGroup>
      ) : (
        <Col
          className={colClass}
          xs={xs}
          sm={sm}
          md={md}
          lg={lg}
          xl={xl}
          xxl={xxl}
        >
          <FormGroup className={formGroupClass}>{renderInput()}</FormGroup>
        </Col>
      )}
    </>
  )
}

export default ReactSelect
