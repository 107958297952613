import React from 'react'
import { Button, Card, Col, Image } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import TicketIcon from '../../../assets/icons/sidebar/ticket.svg'
import CardColumn from '../../customer/components/CardColumn'
import { useScreenSizes } from '../../../utils/helperFunctions'
import { CLASSES } from '../../../assets/styles/classes'
import TicketDetails from '../../ticket/pages/TicketDetails'
import { SERVER_URL } from '../../../config'
import { store } from '../../../store'

function CardElement({ icon = undefined, data, page }) {
  const navigate = useNavigate();
  
  const CardColumn = ({ column_name, element }) => {
    return (
      <div className='d-flex align-items-center flex-column mb-3'>
        <b style={{ fontSize: '14px' }}>{column_name}</b>
        <p
          className={
            useScreenSizes().isDesktopOrLaptop ? 'mt-3 mb-0' : 'mt-1 mb-0'
          }
          style={{ fontSize: '13px' }}
        >
          {element}
        </p>
      </div>
    )
  }

  const downloadInvoice = async (billing_id) => {
    try {
      const response = await fetch(SERVER_URL + 'invoice/download-pdf', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + store.getState().auth.token,
        },
        body: JSON.stringify({ billing_id }), // Sending invoiceId in the request body
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob(); // Get the response as a blob
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'invoice.pdf'); // Set the filename
      document.body.appendChild(link);
      link.click(); // Programmatically click the link to trigger the download
      document.body.removeChild(link); // Clean up
    } catch (error) {
      console.error('Error downloading invoice:', error);
      // Handle error (e.g., show a notification)
    }
  }
  const customer_name = data.customer_last_name ? `${data.customer_first_name} ${data.customer_last_name}` : data.customer_first_name;
  const customer_id = data.customer_id;
  const ticket_id = data.ticket_id;
  const issue = data.issue_type;
  const ticket_status_name = data.ticket_status_name;
  const created_at = data.created_at;
  const total = data.total;
  const billing_id = data.billing_id;

  return (
    <Card className='w-100 shadow-basic border-1 mb-2'>
      <Card.Body className='d-flex align-items-center'>
        <Image src={icon ?? TicketIcon} />
        <span style={{ marginLeft: '10px', fontSize: '14px' }}>
          Dummy-Ticket-ID
        </span>
      </Card.Body>

      <Card.Body className=''>
        <div className={useScreenSizes().isTabletOrMobile ? 'd-block' : CLASSES.content_evenly + ' flex-wrap '} >
          <CardColumn column_name='Customer Id' element={customer_id} />
          <CardColumn column_name='Customer Name' element={customer_name} />
          <CardColumn column_name='Ticket Id' element={ticket_id} />
          <CardColumn column_name='Issue' element={issue} />
          <CardColumn column_name='Status' element={ticket_status_name} />
          <CardColumn column_name='Date' element={created_at} />
          <CardColumn column_name='Total' element={total} />
          <CardColumn
            column_name='Print'
            element={
              <Button onClick={() => downloadInvoice(billing_id)} variant='success' className='text-white btn-sm'>
                Print Invoice
              </Button>
            }
          />
        </div>
      </Card.Body>
    </Card>
  )
}

export default CardElement;
