import request from '../utils/request'
// import { encodeQuery } from '../utils/helperFunctions'
import { FETCH_TYPES } from '../utils/constants'
import { REST_API } from './API_Routes'
import ServiceBase from './ServiceBase'

export default class EquipmentRequests extends ServiceBase {
  create_or_update_equipment_request = async (SUBMIT_VALUES) => {
    const formData = new FormData()
    Object.keys(SUBMIT_VALUES).forEach((key) => {
      if (SUBMIT_VALUES[key]) {
        if (Array.isArray(SUBMIT_VALUES[key])) {
          if (key === 'user_document') {
            SUBMIT_VALUES[key].forEach((item) => {
              formData.append(key + '[]', item)
            })
          } else {
            formData.append(key, JSON.stringify(SUBMIT_VALUES[key]))
          }
        } else {
          formData.append(key, SUBMIT_VALUES[key])
        }
      }
    })

    const option = {
      method: FETCH_TYPES.POST,
      body: formData,
    }
    const token = true
    const isFormData = true
    const response = await request(
      SUBMIT_VALUES?.id
        ? REST_API.EQUIPMENT_REQUEST_UPDATE
        : REST_API.EQUIPMENT_REQUEST_CREATE,
      option,
      token,
      isFormData,
    )
    return response
  }

  viewBySite = async (id) => {
    const response = await request(REST_API.EQUIPMENT_REQUEST_VIEW_BY_SITE + id)
    return response
  }

  equipmentRequestItemApproved = async (body) => {
    const option = {
      method: FETCH_TYPES.POST,
      body,
    }
    const response = await request(
      REST_API.EQUIPMENT_REQUEST_ITEM_APPROVED,
      option,
    )
    return response
  }
}
