import React from 'react'
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  InputGroup,
} from 'react-bootstrap'
import moment from 'moment'
import { CLASSES } from '../../../assets/styles/classes'
import MoreIcon from '../../../assets/icons/common/more.svg'
import EditIcon from '../../../assets/icons/common/edit.svg'
import DeleteIcon from '../../../assets/icons/common/delete.svg'
import DownloadIcon from '../../../assets/icons/common/download.svg'
import PlusIcon from '../../../assets/icons/common/plus-white.svg'
import UserIcon from '../../../assets/icons/sidebar/users-1.svg'
import TicketIcon from '../../../assets/icons/sidebar/ticket.svg'
import DropDownIcon from '../../../assets/icons/common/down-arrow-solid.svg'

import Table from '../../../components/table/Table'
import { fakeData } from '../../../utils/faker'
import DropDownX from '../../../components/extras/DropDownX'
import SearchFilter from '../../../components/extras/SearchFilter'
import { useNavigate } from 'react-router-dom'
import HorizontalNavbar from '../components/HorizontalNavbar'
import HeaderInfo from '../../../components/extras/HeaderInfo'
import BillingDetails from '../components/BillingDetails'
import CardX from '../../../components/layout/CardX'
import RelevantAssets from '../components/RelevantAssets'
import { useDispatch } from 'react-redux'
import { setLoading } from '../../../store/reducers'
import { TicketsService } from '../../../services'
import REST_API from '../../../services/API_Routes'
import { TICKET_STATUS_OPTIONS, TICKET_TYPE_OPTIONS } from '../../../utils/static_data'
import FilesDownload from '../components/FilesDownload'
import CustomerCommunicate from '../../customer/components/CustomerCommunicateTab'

 const TicketDetails = React.forwardRef(({type = 'page', id = null}, ref) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [data, setData] = React.useState({})
  const [loading, setLoading] = React.useState(true)
  const [tab, setTab] = React.useState('Details')

  const {assets, billing, customer, attachments, ...ticket} = data?.ticket ?? {}
  const files = attachments ? attachments.map((item) => ({ url: (item.attachment_url) })) :  []


  React.useEffect(() => {
    if (id) {
      getData()
    }
  }, [id])

  const getData = async () => {
    // dispatch(setLoading({ value: true }))
    setLoading(true)
    try {
      const response = await TicketsService.view({
        route: REST_API.TICKET_VIEW + id,
      })
      if (response.success) {
        setData(response?.data)
      }
    } catch (error) {
      console.error('Error fetching customer data:', error)
    } finally {
      // dispatch(setLoading({ value: false }))
      setLoading(false)
    }
  }

  return (
    <Container ref={ref} fluid className='p-4 py-2 mt-2' style={{ opacity: loading ? 0.5 : 1 }}>
      <CardX border={type === 'modal' ? '0' : '1'}>
        
        <div className={CLASSES.content_between}>
          <h4 className={CLASSES.items_center}>
            {' '}
            <Image src={TicketIcon} className='me-3' />
            Ticket: {ticket?.ticket_no}
          </h4>
          {/* <div className={CLASSES.items_center + 'text-bold'}>
             Total Ticket: 500
           </div> */}
        </div>

        <HorizontalNavbar
          handleSearch={(val) => setTab(val.ticket_status)}
          data={[{label: 'Details', value: 'Details'}, {label: 'Communication', value: 'Communication'}]}
          className='my-1 d-flex align-items-center'
        />
        {tab === 'Details' ? <>
        <h6 className={CLASSES.items_center + 'text-orange my-4'}>
          {TICKET_STATUS_OPTIONS.find((item) => item.value == ticket?.ref_ticket_status_id)?.label}
        </h6>

        <div
          style={{ borderBottom: '1px solid #C8C8C8' }}
          className={CLASSES.content_between + 'my-3'}
        >
          <h5 style={{ color: '#464646' }} className={CLASSES.items_center}>
            Ticket Details
          </h5>
          <div onClick={() => navigate(`/ticket/edit/${ticket?.ticket_id}`)} className={CLASSES.items_center + 'text-bold pointer'}>
            <Image src={EditIcon} className='me-2' />
            Edit
          </div>
        </div>

        <ul style={{ color: '#373737' }} className='p-0'>
          {[
            { label: 'Customer Name:', value: customer?.customer_first_name + ' ' + customer?.customer_last_name },
            { label: 'Customer Id:', value: customer?.customer_id },
            { label: 'Item Name:', value: ticket?.item_name },
            { label: 'Issue Type:', value: ticket?.issue_type },
            { label: 'Ticket type:', value: TICKET_TYPE_OPTIONS.find((item) => item.value == ticket?.ticket_type_id)?.label },
            { label: 'Issue Date:', value: ticket?.issue_date },
            { label: 'Pick Time:', value: `${ticket?.pick_time} - ${ticket?.delivery_time}`}, //'12:30PM -5:00 PM'
            { label: 'Analysis Date:', value: ticket?.analysis_date },
          ].map((item, i) => (
            <li className={CLASSES.items_center+ 'gap-3 my-3'} key={i}>
              <div >{item.label}</div>
              <div>{item.value}</div>
            </li>
          ))}
        </ul>

        <div className='my-5'>
          <h5>Note:</h5>
          <p style={{ maxWidth: 500 }}>
            {ticket?.reason_for_services}
          </p>
        </div>

        <div>
          <h5>Attachment</h5>
          <div className='mt-3'>
            {/* <input type='button' value={'document-name.PDF'} />
            <Image src={DownloadIcon} className='ms-3' /> */}
            <FilesDownload files={files} />
          </div>
        </div>

        <div className='pb-4'>
          <RelevantAssets assetsFields={assets} />
        </div>
        <BillingDetails data={data}/>
        </> : <CustomerCommunicate data={data} />}
      </CardX>
    </Container>
  )
});
export default TicketDetails;