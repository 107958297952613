import {createSlice} from '@reduxjs/toolkit';

export const alert = createSlice({
  name: 'alert',
  initialState: {
    show: false,
    success: false,
    message: 'Something went wrong.',
    title: 'Oops!',
  },
  reducers: {
    setAlert: (state, {payload}) => ({
      ...state,
      ...payload,
      message: payload.message || 'Something went wrong.',
    }),
    setSuccessAlert: (state, {payload}) => ({
      ...state,
      show: true,
      success: true,
      title: payload?.title || 'Successfully Completed',
      message: payload?.message || 'Thank you',
    }),
    setFailedAlert: (state, {payload}) => ({
      ...state,
      show: true,
      success: false,
      title: payload?.title || 'Oops!',
      message: payload?.message || 'Something went wrong.',
    }),
  },
});

export const {setAlert, setSuccessAlert, setFailedAlert} = alert.actions;
export default alert.reducer;
