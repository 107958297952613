import React, { useState } from 'react'
import { Button, Image } from 'react-bootstrap'
//
import { CLASSES, COLORS } from '../../assets/styles/styles'

export default function ButtonWithIcon({
  label = 'Click',
  icon = undefined,
  iconSize = 16,
  onClick,
  color = COLORS.theme,
  textColor = COLORS.white,
  style,
  ...rest
}) {
  // const navigate = useNavigate();
  const [opacity, setOpacity] = useState(1)

  const onBUttonClick = () => {
    setTimeout(() => {
      setOpacity((prevValue) => {
        if (prevValue === 1) {
          return prevValue
        } else return 0.8
      })
    }, 100)
  }
  return (
    <Button
      className={'mr-0 w-100 border-0' + CLASSES.content_center}
      color={'primary'}
      onClick={(e) => {
        onBUttonClick()
        onClick(e)
      }}
      onMouseEnter={() => setOpacity(0.8)}
      onMouseDown={() => setOpacity(0.9)}
      onMouseLeave={() => setOpacity(1)}
      style={{
        backgroundColor: color,
        minHeight: 36,
        fontSize: 14,
        color: textColor,
        opacity,
        ...style,
      }}
      {...rest}
    >
      <Image src={icon} height={iconSize} className={'me-2'} />
       {label}
    </Button>
  )
}
