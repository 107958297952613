import React, { Fragment } from 'react'
import { Button, Col, Container, Image, Row, Dropdown } from 'react-bootstrap'
import moment from 'moment'
import { CLASSES } from '../../assets/styles/classes'
import MoreIcon from '../../assets/icons/common/more.svg'
import EditIcon from '../../assets/icons/common/edit.svg'
import DeleteIcon from '../../assets/icons/common/delete.svg'
import DropDownIcon from '../../assets/icons/common/down-arrow-solid.svg'
// import BarChart from '../../components/common/BarChart'
import PlusIcon from '../../assets/icons/common/plus-white.svg'
import UserIcon from '../../assets/icons/dashboard/user.svg'
import TicketIcon from '../../assets/icons/dashboard/ticket.svg'
import SettingIcon from '../../assets/icons/dashboard/setting.svg'
import FileIcon from '../../assets/icons/dashboard/file.svg'
import OverviewCard from './components/OverviewCard'
import BarGraphCard from './components/BarGraphCard'
import Table from '../../components/table/Table'
import { fakeData } from '../../utils/faker'
import { Link, useNavigate } from 'react-router-dom'
import ActionElements from '../../components/extras/ActionElements'
import Tickets from './Tickets'
import request from '../../utils/request'
import { encodeQuery } from '../../utils/helperFunctions'
import { FETCH_TYPES } from '../../utils/constants'

const items = [
  { title: 'New Customer', link: '/customer/create' },
  { title: 'New Ticket', link: '/ticket/create' },
  { title: 'New Invoice', link: '/invoice/create' },
]
export default function Home() {
  // const [selectedItem, setSelectedItem] = React.useState(items[0])
  const navigate = useNavigate()
  const [data, setData] = React.useState({})

  const getHomeState = async (query) => {
    const queryParam = encodeQuery(query)
    const response = await request('home/stats' + queryParam, {
      method: FETCH_TYPES.GET,
    })
    setData(response)
    console.log(response)
  }

  React.useEffect(() => {
    getHomeState({})
  }, [])

  // Create an array with all months from 1 to 12
  const allMonths = Array.from({ length: 12 }, (_, i) => i + 1)

  // Merge the existing data with all months
  const monthly_customers = (data) =>
    allMonths.map((month) => {
      // Find if the month already exists in the original data
      const existingMonth = data?.find((item) => item?.month === month)

      // If it exists, return it; otherwise, create a new entry with total = 0
      // return existingMonth ? existingMonth : { month, total: 0 };
      return existingMonth ? existingMonth?.total : 0
    })


  return (
    <Container fluid className='p-4 py-2'>
      <div className='mt-2'>
        <div className={CLASSES.content_between}>
          <h4>Overview</h4>
          <Dropdown data-bs-theme='light' className=''>
            <Dropdown.Toggle
              id='dropdown-button-dark-example1'
              className={CLASSES.content_between}
              variant=''
            >
              <Button className={CLASSES.items_center + 'bg-blue'}>
                <Image src={PlusIcon} className={'me-2'} /> Add New
              </Button>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {items.map((item, i) => (
                <Fragment key={i}>
                  {i !== 0 && <Dropdown.Divider />}
                  <Dropdown.Item
                    onClick={() => navigate(item.link)}
                    active={i == 100}
                  >
                    {item.title}
                  </Dropdown.Item>
                </Fragment>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          {/* <Button className={CLASSES.items_center+'bg-blue'}>
            <Image src={PlusIcon} className={'me-2'}/> Add New
          </Button> */}
        </div>
        <Row className='mt-3'>
          {[
            {
              title: 'Total Customer',
              icon: UserIcon,
              style: {
                border: '1px solid #9ED0F2',
                backgroundColor: '#56ADE9',
              },
              value: data?.total_customers || 0,
            },
            {
              title: 'Total Ticket',
              icon: TicketIcon,
              style: {
                border: '1px solid #95E6A2',
                backgroundColor: '#46D45D',
              },
              value: data?.total_tickets || 0,
            },
            {
              title: ' Todays Customer',
              icon: SettingIcon,
              style: {
                border: '1px solid #F9CD9F',
                backgroundColor: '#F5A859',
              },
              value: data?.todays_customers || 0,
            },
            {
              title: 'Todays Ticket',
              icon: FileIcon,
              style: {
                border: '1px solid #9ED0F2',
                backgroundColor: '#56ADE9',
              },
              value: data?.todays_tickets || 0,
            },
          ].map((item, i) => (
            <Col key={i} lg={3} md={4} sm={6} xs={12} className='mb-3'>
              <OverviewCard
                style={item.style}
                icon={item.icon}
                title={item.title}
                value={item.value}
              />
            </Col>
          ))}
        </Row>
      </div>

      <div className='mt-4'>
        <h4>Yearly Activity </h4>
        <Row className='mt-3'>
          <Col lg={6} className='mb-3'>
            <BarGraphCard
              style={{ border: '1px solid #9ED0F2' }}
              getHomeState={getHomeState}
              route='home/customer/stats/'
              name='Customers'
              data={monthly_customers(data?.monthly_customers)}
            />
          </Col>
          <Col lg={6} className='mb-3'>
            <BarGraphCard
              style={{ border: '1px solid #9ED0F2' }}
              getHomeState={getHomeState}
              route='home/tickets/stats/'
              name='Tickets'
              data={monthly_customers(data?.monthly_tickets)}
            />
          </Col>
        </Row>
      </div>

      <div className='mt-4'>
        <h4>Recent Activity </h4>
        {/* <Table
          columns={columns}
          data={fakeData(5)}
         
          className='mb-5 mt-3 border-radius-10 black-shadow-15'
          style={{ border: '1px solid #9ED0F2' }}
        /> */}

        <Tickets />
      </div>
    </Container>
  )
}
