import React from 'react'
import { CLASSES, COLORS } from '../../../assets/styles/classes'
import Table from '../../../components/table/Table'
import { fakeData } from '../../../utils/faker'

import { useNavigate } from 'react-router-dom'

export default function BillingDetails({data}) {
  const navigate = useNavigate()
  const {assets, billing, ...ticket} = data?.ticket ?? {}

  const columns = [
    {
      name: 'Service',
      style: {
        fontWeight: 'bold',
      },
      cell: ({ title, asset_name }) => title || asset_name,
      conditionalCellStyles: [
        {
          when: (row) => {
            return row.title === 'Subtotal'
          },
          style: {
            color: COLORS.orange,
          },
        },
        {
          when: (row) => {
            return row.title === 'Total Due'
          },
          style: {
            color: COLORS.orange,
          },
        },
      ],
    },
    {
      name: 'Qty',
      minWidth: '180px',
      cell: ({ title, asset_qty }) => (asset_qty),
    },
    {
      name: 'Rate',
      minWidth: '180px',
      cell: ({ title, asset_price }) => (asset_price),
    },
    {
      name: 'Guarantee',
      minWidth: '180px',
      cell: ({ title, asset_warranty_period }) => asset_warranty_period,
    },
    {
      name: 'Line total',
      minWidth: '180px',
      cell: ({ amount, asset_price, asset_qty, title }) => {
        return amount || `$${Number(asset_price) * asset_qty}`
      },
      conditionalCellStyles: [
        {
          when: (row) => {
            return row.title === 'Total Due'
          },
          style: {
            color: COLORS.orange,
          },
        },
      ],
    },
  ]

  const conditionalRowStyles = [
    {
      when: row => {
        return row.title === 'Subtotal'
      },
      style: {
        borderTop: `1px solid #C8C8C8`,
      },
    },
    {
      when: row => {
        return row.title === 'Total Due'
      },
      style: {
        borderTop: `1px solid #C8C8C8`,
      },
    },
  ];

  const subTotal = assets?.reduce(
    (acc, curr) => acc + Number(curr?.asset_price) * curr?.asset_qty,
    0,
  )

  return (
      <div className='my-5'>
        <div className={CLASSES.content_between}>
          <h4 className={CLASSES.items_center}>Billing Details</h4>
        </div>

        <div className='mb-5 mt-3 border-radius-10 black-shadow-15'>
          <Table
            columns={columns}
            data={[
              ...assets || [],
              {title: 'Subtotal', amount: `$${subTotal?.toFixed(2)}`},
              {title: 'Discount', amount: `$${billing?.discount}`},
              {title: 'Service Charge', amount: `$${billing?.service_charge}`},
              {title: 'Tax (5%)', amount: `$${billing?.tax}`},
              {title: 'Total Due', amount: `$${billing?.total}`},
            ]}
            pagination={false}
            customStyle={{
              rows: {
                style: {
                  borderTop: '0px solid #E0E0E0',
                  borderBottom: '0px solid #E0E0E0 !important',
                  display: 'flex !important',
                  alignItems: 'center !important',
                  padding: 15,
                },
              },
            }}
            conditionalRowStyles={conditionalRowStyles}
          />
        </div>
      </div>
  )
}
