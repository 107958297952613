import { createSlice } from '@reduxjs/toolkit';

export const language = createSlice({
  name: 'LANGUAGE',
  initialState: {
    name: localStorage.getItem('language_name') || 'EN',
  },
  reducers: {
    setLanguage: (state, { payload }) => ({
      ...state,
      name: payload,
    }),
  },
});

export const { setLanguage } = language.actions;
export default language.reducer;
