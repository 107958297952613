import React from 'react'
import Card from 'react-bootstrap/Card';
import CustomerCardInfo from './CustomerCardInfo';
import { Button, Image } from 'react-bootstrap';
import EditIcon from '../../../assets/icons/common/edit.svg';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const CharacterCard = ({ data }) => {
  const navigate = useNavigate();
  return (
    <Card style={{ width: '100%', fontSize: '14px' }}>
      <Card.Body>
        <Card.Subtitle className="text-muted">Overview</Card.Subtitle>
        <div className='my-3 d-flex justify-content-center mt-2'>
          <Card.Img style={{ borderRadius: '50%', height: '132px', width: '132px' }} rounded variant="top" src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fdevelopers.elementor.com%2Fdocs%2Fassets%2Fimg%2Felementor-placeholder-image.png&f=1&nofb=1&ipt=14d5ab0300ae4a0f1624e024b1ea7c6e2d4ff1c853fb59c7535061e58f62651e&ipo=images" />
        </div>
        <hr />
        <div className='px-3 py-2' style={styles.border}>
          <CustomerCardInfo fieldName="Customer Name" fieldValue={data.customer_name} />
          <CustomerCardInfo fieldName="Customer ID" fieldValue={data.customer_id} />
          <CustomerCardInfo fieldName="Company Name" fieldValue={data.customer_business_name} />
          <CustomerCardInfo fieldName="Phone Number" fieldValue={data.customer_phone1? data.customer_phone1:data.customer_phone2}/>
          <CustomerCardInfo fieldName="Email Address" fieldValue={data.customer_email_1? data.customer_email_1:data.customer_email_2} />
          <CustomerCardInfo fieldName="Address" fieldValue={data.customer_address} />
          <CustomerCardInfo fieldName={<b>Referral By</b>} fieldValue={`Customer ( ${data.referred_by_customer_id? data.referred_by_customer_id: '-'} )`} />
        </div>
        <div className='mt-4'>
          <Button onClick={() => navigate(`/customer/edit/${data.customer_id}`)} className='w-100 py-2'  style={{ ...styles.border, display: 'flex', alignItems: 'center', justifyContent: 'center' }} variant="white">
            <Image src={EditIcon} className='mx-2' /> Edit
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
}
const styles = {
  border: {
    border: '1px solid #C8C8C8',
    borderRadius: '5px'
  }
};
export default CharacterCard;