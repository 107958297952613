import { Col, Image } from 'react-bootstrap'
//
import CancelIcon from '../../../assets/icons/common/cancel.svg'
import ProgressIcon from '../../../assets/icons/common/progress.svg'
// import DownloadedIcon from '../../../assets/icons/common/downloaded.svg'
// import DeleteIcon from '../../../assets/icons/common/delete.svg'
import { CLASSES } from '../../../assets/styles/styles'

export default function TicketAttachments({
  attachmentFields,
  removeAttachment,
  watch,
}) {
  return (
    <Col lg={4} md={6}>
      {attachmentFields.length > 0 &&
        attachmentFields.map((field, index) => {
          return (
            <div key={index} className='my-4 d-flex border'>
              <Image src={ProgressIcon} className='me-3' />
              <div className={CLASSES.content_between + ' text-bold w-100'}>
                <div>
                  <div>File {index + 1}</div>
                  <div>
                    Size{' '}
                    {watch('ticket_attachments_files')[index].size ? (
                      <>
                        {(
                          watch('ticket_attachments_files')[index].size / 1024
                        ).toFixed(2) > 1024
                          ? (
                              watch('ticket_attachments_files')[index].size /
                              1024
                            ).toFixed(2) + ' MB'
                          : (
                              watch('ticket_attachments_files')[index].size /
                              1024
                            ).toFixed(2) + ' KB'}
                      </>
                    ) : (
                      'N/A'
                    )}
                  </div>
                </div>
                <Image
                  height={40}
                  src={CancelIcon}
                  className='ms-3 pointer'
                  onClick={() => removeAttachment(index)}
                />
              </div>
            </div>
          )
        })}
    </Col>
  )
}
