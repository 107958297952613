import React from 'react'
import { CLASSES, COLORS } from '../../../assets/styles/classes'
import Table from '../../../components/table/Table'
import { useNavigate } from 'react-router-dom'
import ReactstrapInput from '../../../components/form/input-fields/ReactstrapInput'

export default function BillingDetailsCreate({
  isEditMode = false,
  fields,
  control,
  watch,
}) {
  const navigate = useNavigate()
  const columns = [
    {
      name: 'Service',
      style: {
        fontWeight: 'bold',
      },
      cell: ({ title }) => title || 'Item Name',
      conditionalCellStyles: [
        {
          when: (row) => {
            return row.title === 'Subtotal'
          },
          style: {
            color: COLORS.orange,
          },
        },
        {
          when: (row) => {
            return row.title === 'Total Due'
          },
          style: {
            color: COLORS.orange,
          },
        },
      ],
    },
    {
      name: 'Qty',
      minWidth: '180px',
      cell: ({ title, asset_qty }) => (asset_qty),
    },
    {
      name: 'Rate',
      minWidth: '180px',
      cell: ({ title, asset_price }) => (asset_price),
    },
    {
      name: 'Guarantee',
      minWidth: '180px',
      cell: ({ title, asset_warranty_period }) => asset_warranty_period,
    },
    {
      name: 'Line total',
      minWidth: '180px',
      cell: ({ amount, asset_price, asset_qty, title }) => {
        if (title === 'Discount') {
          return (
            <ReactstrapInput
              control={control}
              name='discount'
              type='number'
              showError={false}
            />
          )
        }
        if (title === 'Service Charge') {
          return (
            <ReactstrapInput
              control={control}
              name='serviceCharge'
              type='number'
              showError={false}
            />
          )
        }
        if (title === 'Tax (5%)') {
          return `$${Number(amount).toFixed(2)}`
        }
        if (title === 'Total Due') {
          return amount
        } else {
          return amount || `$${Number(asset_price) * asset_qty}`
        }
      },
      conditionalCellStyles: [
        {
          when: (row) => {
            return row.title === 'Total Due'
          },
          style: {
            color: COLORS.orange,
          },
        },
      ],
    },
  ]

  const conditionalRowStyles = [
    {
      when: (row) => {
        return row.title === 'Subtotal'
      },
      style: {
        borderTop: `1px solid #C8C8C8`,
      },
    },
    {
      when: (row) => {
        return row.title === 'Total Due'
      },
      style: {
        borderTop: `1px solid #C8C8C8`,
      },
    },
  ]

  const data = React.useMemo(() => {
    const subTotal = fields.reduce(
      (acc, curr) => acc + Number(curr?.asset_price) * curr?.asset_qty,
      0,
    )
    let total_due = (subTotal + subTotal * 0.05 + Number(watch('serviceCharge')) - Number(watch('discount'))) 
    return [
      ...fields,
      { title: 'Subtotal', amount: `$${subTotal.toFixed(2) || 0}` },
      { title: 'Discount', amount: watch('discount') },
      { title: 'Service Charge', amount: watch('serviceCharge') },
      { title: 'Tax (5%)', amount: subTotal * 0.05 || 0 },
      { title: 'Total Due', amount: `$${total_due.toFixed(2) || 0}` },
    ]
  }, [watch('discount'), watch('serviceCharge'), fields.length])

  return (
    <div className='my-5'>
      <div className={CLASSES.content_between}>
        <h4 className={CLASSES.items_center}>Billing Details</h4>
      </div>

      <div className='mb-5 mt-3 border-radius-10 black-shadow-15'>
        <Table
          columns={columns}
          data={data}
          pagination={false}
          customStyle={{
            rows: {
              style: {
                borderTop: '0px solid #E0E0E0',
                borderBottom: '0px solid #E0E0E0 !important',
                display: 'flex !important',
                alignItems: 'center !important',
                padding: 15,
              },
            },
          }}
          conditionalRowStyles={conditionalRowStyles}
        />
      </div>
    </div>
  )
}
