export const Languages = ['English', 'Italian']

export const FETCH_TYPES = {
  GET: 'GET',
  POST: 'POST',
  PATCH: 'PATCH',
  PUT: 'PUT',
  DELETE: 'DELETE',
}

export const PAGINATION_TYPES = {
  TICKETS: 'TICKETS',
  PROJECTS: 'PROJECTS',
  REQUESTS: 'REQUESTS',
  CATEGORIES: 'CATEGORIES',
  STOCKS: 'STOCKS',
  WAREHOUSES: 'WAREHOUSES',
  REQUISITION: 'REQUISITION',
  SITES: 'SITES',
  EMPLOYEES: 'EMPLOYEES',

  CUSTOMERS: 'CUSTOMERS',
  ASSET: 'ASSET',
  INVOICES: 'INVOICES',
  APPOINTMENTS: 'APPOINTMENTS',
}

export const LOADING_NAME = {
  ...PAGINATION_TYPES,
  COMMON : 'common',
  LOGIN: 'LOGIN',
}

export const TABLE_ROWS_PER_PAGE = 20;
export const TABLE_ROWS_PER_PAGE_OPTIONS = [5, 10, 15, 20, 25, 30];

export const FONT_SIZE = {
  table_data: 14,
  table_header: '1rem',
  button: 14,
  input: 14,
  base: 14,
}

export const DOWNLOAD_BUTTON_HEIGHT = 30
