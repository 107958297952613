/**
 * RULES
 *  1. use uppercase
 *  2. use lowercase if end-point have sub routes
 */
export const REST_API = {
  LOGIN: 'login',
  LOGOUT: 'logout',
  REGISTER: 'registration_request',
  
  SITES_LIST: 'sites/list',
  SITES_CREATE: 'sites/create',
  SITES_UPDATE: 'sites/update',
  SITES_VIEW: 'sites/view/',
  SITES_DELETE: 'sites/delete',
  SITES_SEARCH: 'sites/search',

  WAREHOUSE_LIST: 'warehouse/list',
  WAREHOUSE_CREATE: 'warehouse/create',
  WAREHOUSE_UPDATE: 'warehouse/update',
  WAREHOUSE_VIEW: 'warehouse/view/',
  WAREHOUSE_DELETE: 'warehouse/delete',
  WAREHOUSE_SEARCH: 'warehouse/search',

  EQUIPMENT_REQUESTS: 'request/list',
  EQUIPMENT_REQUEST_CREATE: 'request/create',
  EQUIPMENT_REQUEST_UPDATE: 'request/update',
  EQUIPMENT_REQUEST_ITEM_APPROVED: 'request/approve',
  EQUIPMENT_REQUEST_VIEW: 'request/view/',
  EQUIPMENT_REQUEST_VIEW_BY_SITE: 'request/view-by-site/',
  EQUIPMENT_REQUEST_DELETE: 'request/delete',
  EQUIPMENT_REQUEST_SEARCH: 'request/search',

  CATEGORIES: 'category/list',
  CATEGORIES_CREATE: 'category/create',
  CATEGORIES_UPDATE: 'category/update',
  CATEGORIES_VIEW: 'category/view/',
  CATEGORIES_DELETE: 'category/delete',
  CATEGORIES_SEARCH: 'category/search',

  EQUIPMENTS_LIST: 'equipments/list',
  EQUIPMENTS_LIST_BY_SITE: 'equipments/site-equipment-list',
  EQUIPMENTS_CREATE: 'equipments/create',
  EQUIPMENTS_UPDATE: 'equipments/update',
  EQUIPMENTS_VIEW: 'equipments/view/',
  EQUIPMENTS_DELETE: 'equipments/delete',
  EQUIPMENTS_SEARCH: 'equipments/search',

  TEAM_LIST: 'teams/list',
  TEAM_CREATE: 'teams/create',
  TEAM_UPDATE: 'teams/update',
  TEAM_VIEW: 'teams/view/',
  TEAM_DELETE: 'teams/delete',
  TEAM_EXPORT_DOWNLOAD: 'exports/team-xls',

  REQUISITION_LIST: 'requisition/list',
  REQUISITION_CREATE: 'requisition/create',
  REQUISITION_UPDATE: 'requisition/update',
  REQUISITION_VIEW: 'requisition/view/',
  REQUISITION_APPROVE: 'requisition/approve',
  REQUISITION_REJECT: 'requisition/reject',
  REQUISITION_DELETE: 'requisition/delete',
  REQUISITION_SEARCH: 'requisition/search',
  REQUISITION_EXPORT_DOWNLOAD: 'exports/requisition-xls',

  TICKET_CREATE: 'ticket/create',
  TICKET_UPDATE: 'ticket/update',
  TICKET_LIST: 'ticket/list',
  TICKET_VIEW: 'ticket/view/',
  TICKET_DELETE: 'ticket/delete',
  TICKET_SEARCH: 'ticket/search',

  PROJECT_LIST: 'project/list',
  PROJECT_CREATE: 'project/create',
  PROJECT_UPDATE: 'project/update',
  PROJECT_VIEW: 'project/view/',
  PROJECT_DELETE: 'project/delete',
  PROJECT_SEARCH: 'project/search',

  EMPLOYEE_LIST: 'employee/list',
  EMPLOYEE_CREATE: 'employee/create',
  EMPLOYEE_UPDATE: 'employee/update',
  EMPLOYEE_VIEW: 'employee/view/',
  EMPLOYEE_DELETE: 'employee/delete',
  EMPLOYEE_SEARCH: 'employee/search',

  //
  NOTIFICATIONS: 'notifications',

  CUSTOMER: 'customer/list',
  CUSTOMER_CREATE: 'customer/create',
  CUSTOMER_UPDATE: 'customer/update',
  CUSTOMER_EDIT_VIEW: 'customer/edit_view/',
  CUSTOMER_VIEW: 'customer/view/',
  CUSTOMER_DELETE: 'customer/deactivate/',
  CUSTOMER_SEARCH: 'category/search',

  ASSET: 'asset/list',
  ASSET_CREATE: 'asset/create',
  ASSET_UPDATE: 'asset/update',
  ASSET_EDIT_VIEW: 'asset/edit_view/',
  ASSET_VIEW: 'asset/view/',
  ASSET_DELETE: 'asset/deactivate/',
  ASSET_SEARCH: 'asset/search',

  INVOICE: 'invoice/list',

  APPOINTMENT: 'appointment/list',
  APPOINTMENT_CREATE: 'appointment/create',
  APPOINTMENT_UPDATE: 'appointment/update',
  APPOINTMENT_EDIT_VIEW: 'appointment/edit_view/',
  APPOINTMENT_VIEW: 'appointment/view/',
  APPOINTMENT_DELETE: 'appointment/deactivate/',
  APPOINTMENT_SEARCH: 'appointment/search',

  //States
  STATES_LIST: 'states',
  CITY_LIST: 'cities/',
}

export default REST_API;
