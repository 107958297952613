import React from 'react'
import ActionElements from '../../../components/extras/ActionElements'
import { useNavigate } from 'react-router-dom'
import ModalX from '../../../components/others/ModalX'
import TicketDetails from '../pages/TicketDetails'
import { useDispatch } from 'react-redux'
import { deletePaginationData } from '../../../store/actions/pagination'
import { PAGINATION_TYPES } from '../../../utils/constants'
import REST_API from '../../../services/API_Routes'
import { TicketsService } from '../../../services'

export default function TableAction({ data }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = React.useState(false)

  const handleDelete = (id) => {
    dispatch(
      deletePaginationData({
        id,
        key: PAGINATION_TYPES.TICKETS,
        ServiceName: TicketsService,
        route: REST_API.TICKET_DELETE,
        primaryKey: 'ticket_id',
        delete_key: 'ticket_id',
        value: {
          ticket_id: id,
          ticket_active: 0,
        },
      }),
    )
  }

  return (
    <>
      <ActionElements
        id={data.ticket_id}
        editClick={(id) => navigate(`/ticket/edit/${data.ticket_id}`)}
        deleteClick={handleDelete}
        viewClick={() => {
          setIsOpen(true)
          // navigate(`/ticket/details/1`, { state: { id: itemId } })
        }}
      />
      <ModalX isOpen={isOpen} toggle={() => setIsOpen(false)} size='xl'>
        <TicketDetails type='modal' id={data.ticket_id} />
      </ModalX>
    </>
  )
}
