import React, { useEffect, useState } from 'react'
import { Button, Container, Image } from 'react-bootstrap'
import { CLASSES } from '../../assets/styles/classes'
import Table from '../../components/table/Table'
import { useDispatch, useSelector } from 'react-redux'
import { PAGINATION_TYPES } from '../../utils/constants'
import TableAction from '../ticket/components/TableAction'
import getPaginationData from '../../store/actions/pagination'
import { TicketsService } from '../../services'
import { viewDateFormat } from '../../utils/helperFunctions'
import { TICKET_STATUS_OPTIONS } from '../../utils/static_data'

const columns = [
  // {
  //   name: 'Ticket Id',
  //   width: '250px',
  //   style: {
  //     fontWeight: 'bold',
  //   },
  //   cell: ({ ticket_id }) => ticket_id,
  // },
  {
    name: 'Ticket Id',
    width: '150px',
    style: {
      fontWeight: 'bold',
    },
    cell: ({ ticket_no }) => ticket_no,
  },
  {
    name: 'Customer Name',
    minWidth: '180px',
    cell: ({ customer }) =>
      customer?.customer_first_name + ' ' + customer?.customer_last_name,
  },
  {
    name: 'Phone Number',
    minWidth: '180px',
    cell: ({ customer }) => customer?.customer_phone1 || 'N/A',
  },
  {
    name: 'Email',
    minWidth: '180px',
    cell: ({ contact_email }) => contact_email || 'N/A',
  },
  {
    name: 'Issue',
    minWidth: '180px',
    cell: ({ item_name }) => item_name,
  },
  {
    name: 'Status',
    minWidth: '180px',
    center: true,
    cell: ({ref_ticket_status_id}) => (
      <div
        style={{ borderRadius: 20, border: '1px solid #F3922F' }}
        className={CLASSES.items_center + 'p-1 px-2 text-orange'}
      >
        {TICKET_STATUS_OPTIONS.find((item) => item.value == ref_ticket_status_id).label}
        {/* <Image src={DropDownIcon} className={'ms-2'} /> */}
      </div>
    ),
  },
  {
    name: 'Date',
    // minWidth: '180px',
    cell: ({ created_at }) => viewDateFormat(created_at),
  },
  {
    name: 'Actions',
    width: '120px',
    style: { paddingRight: '30px' },
    center: true,
    allowOverflow: true,
    cell: (row) => <TableAction data={row} />,
  },
]

export default function Tickets() {
  const dispatch = useDispatch()
  const { items, currentPage, rowsPerPage, total } = useSelector(
    ({ pagination }) => pagination[PAGINATION_TYPES.TICKETS],
  )
  const isLoading = useSelector(
    ({ loader }) => loader[PAGINATION_TYPES.TICKETS],
  )
  const [searchQuery, setSearchQuery] = useState({})

  useEffect(() => {
    dispatch(
      getPaginationData({
        key: PAGINATION_TYPES.TICKETS,
        ServiceName: TicketsService,
        page: currentPage,
        per_page: 5,
        order_by: 'desc',
        ...searchQuery,
      }),
    )
  }, [currentPage, rowsPerPage, searchQuery])


  return (
    <Container fluid className='p-4 py-2 mt-2'>
          <Table
            columns={columns}
            data={items || []}
            progressPending={isLoading}
            // customStyle={{
            //   rows: {
            //     style: {
            //       borderTop: '0px solid #E0E0E0',
            //       borderBottom: '1px solid #E0E0E0 !important',
            //       display: 'flex !important',
            //       alignItems: 'center !important',
            //       padding: 15,
            //     },
            //   },
            // }}
            className='mb-5 mt-3 border-radius-10 black-shadow-15'
            style={{ border: '1px solid #9ED0F2' }}
          />

    </Container>
  )
}
