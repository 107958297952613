import React, { useState, useEffect } from 'react'
import {
  Button,
  ButtonGroup,
  Container,
  Image,
  InputGroup,
} from 'react-bootstrap'
import moment from 'moment'
import 'flatpickr/dist/flatpickr.css'
import Flatpickr from 'react-flatpickr'
import { CLASSES } from '../../../assets/styles/classes'
import MoreIcon from '../../../assets/icons/common/more.svg'
import EyeIcon from '../../../assets/icons/common/eye.svg'
import EditIcon from '../../../assets/icons/common/edit.svg'
import DeleteIcon from '../../../assets/icons/common/delete.svg'
import PlusIcon from '../../../assets/icons/common/plus-white.svg'
import CalendarWhiteIcon from '../../../assets/icons/sidebar/calendar-minus.svg'
import Table from '../../../components/table/Table'
import { useDispatch, useSelector } from 'react-redux'
import getPaginationData, { deletePaginationData } from '../../../store/actions/pagination'
import DropDownX from '../../../components/extras/DropDownX'
import SearchFilter from '../../../components/extras/SearchFilter'
import { useNavigate } from 'react-router-dom'
import ActionElements from '../../../components/extras/ActionElements'
import { PAGINATION_TYPES } from '../../../utils/constants'
import { AppointmentService } from '../../../services'
import { setCurrentPage } from '../../../store/reducers'
import { addOrUpdatePaginationData } from '../../../store/actions/pagination'
import REST_API from '../../../services/API_Routes'
import CustomPagination from '../../../components/table/CustomPagination'

// const columns = (navigate, deleteClick) => [
//   {
//     name: 'Appointment Id',
//     style: { fontWeight: 'bold' },
//     cell: ({ appointment_id }) => appointment_id.substr(0, 8),
//   },
//   {
//     name: 'Customer Name',
//     minWidth: '180px',
//     cell: ({ customer_name }) => customer_name,
//   },
//   {
//     name: 'Email',
//     minWidth: '250px',
//     cell: ({ customer_email }) => customer_email,
//   },
//   {
//     name: 'Phone Number',
//     minWidth: '180px',
//     cell: ({ customer_phone }) => customer_phone,
//   },
//   {
//     name: 'Date',
//     minWidth: '130px',
//     cell: ({ appointment_date }) => moment(appointment_date).format('DD MMM, YYYY'),
//   },
//   {
//     name: 'Time',
//     width: '120px',
//     center: true,
//     cell: ({ appointment_time }) => (
//       <div className='bg-blue text-white p-2'>{moment(appointment_time, 'HH:mm:ss').format('hh:mm A')}</div>
//     ),
//   },
//   {
//     name: 'Actions',
//     width: '120px',
//     style: { paddingRight: '30px' },
//     center: true,
//     allowOverflow: true,
//     cell: (row) => (
//       <ActionElements
//         id={row.appointment_id || 1}
//         editClick={() => navigate(`/appointment/edit/${row.appointment_id}`)}
//         deleteClick={() => deleteClick(row.appointment_id)} 
//       />
//     ),
//   },
// ]

const columns = (navigate, deleteClick) => [
  {
    name: 'Time',
    width: '130px',
    style: {
      fontWeight: 'bold',
    },
    style: { borderRight: '2px solid #C8C8C8' },
    cell: ({ appointment_time }) => (
            <div className='bg-blue text-white p-2'>{moment(appointment_time, 'HH:mm:ss').format('hh:mm A')}</div>
          ),
  },
  {
    name: 'Appointment Id',
    width: '180px',
    style: {
      fontWeight: 'bold',
    },
    cell: ({ appointment_unique_id }) => appointment_unique_id,
  },
  {
    name: 'Customer Name',
    minWidth: '180px',
    cell: ({ customer }) => {
      if (customer) {
        return customer.customer_last_name
          ? `${customer.customer_first_name} ${customer.customer_last_name}`
          : customer.customer_first_name;
      }
      return '';
    }
  },
  {
    name: 'Email',
    // minWidth: '250px',
    cell: ({ customer }) => customer.customer_email_1 ? customer.customer_email_1 : customer.customer_email_2,
  },
  {
    name: 'Phone Number',
    // minWidth: '180px',
    cell: ({ customer }) => customer.customer_phone1 ? customer.customer_phone1 : customer.customer_phone2,
  },
  {
    name: 'Date',
    // minWidth: '130px',
    cell: ({ appointment_date }) => moment(appointment_date).format('DD MMM, YYYY'),
  },
  {
    name: 'Type',
    // width: '180px',
    center: true,
    cell: ({appointment_type}) => (
      <div
        style={{ borderRadius: 20, backgroundColor: '#FEF4EA' }}
        className={CLASSES.items_center + 'p-1 px-2 text-orange'}
      >
        {appointment_type}
      </div>
    ),
  },
  {
    name: 'Status',
    // minWidth: '150px',
    center: true,
    cell: ({ status }) => (
      <div
        style={{ borderRadius: 20, border: '1px solid #F3922F' }}
        className={CLASSES.items_center + 'p-1 px-2 text-orange'}
      >
        {status ? status.appointment_status_name : 'Unknown'} {/* Defaulting to 'Unknown' if status is not present */}
      </div>
    ),
  },
  {
        name: 'Actions',
        width: '120px',
        style: { paddingRight: '30px' },
        center: true,
        allowOverflow: true,
        cell: (row) => (
          <ActionElements
            id={row.appointment_id || 1}
            editClick={() => navigate(`/appointment/edit/${row.appointment_id}`)}
            deleteClick={() => deleteClick(row.appointment_id)} 
          />
        ),
      },
]

export default function Appointments() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { items, currentPage, rowsPerPage } = useSelector(
    ({ pagination }) => pagination[PAGINATION_TYPES.APPOINTMENTS],
  )
  const isLoading = useSelector(({ loader }) => loader[PAGINATION_TYPES.APPOINTMENTS])
  const [searchQuery, setSearchQuery] = useState({})

  useEffect(() => {
    dispatch(
      getPaginationData({
        key: PAGINATION_TYPES.APPOINTMENTS,
        ServiceName: AppointmentService,
        page: currentPage,
        per_page: rowsPerPage,
        ...searchQuery,
      }),
    )
  }, [currentPage, rowsPerPage, searchQuery])

  const handleSearch = (query) => {
    setSearchQuery(query)
    dispatch(
      setCurrentPage({
        key: PAGINATION_TYPES.APPOINTMENTS,
        currentPage: 1,
      }),
    )
  }

 
  const deleteClick = async (id) => {
    dispatch(
      deletePaginationData({
        id,
        key: PAGINATION_TYPES.APPOINTMENTS,
        ServiceName: AppointmentService,
        primaryKey: 'appointment_id',
        route: REST_API.APPOINTMENT_DELETE + id,
        value: { appointment_status_id: 0 }, // Assuming a value to mark as deleted
      }),
    )
  }

  return (
    <Container fluid className='p-4 py-2 mt-2'>
      <div>
        <div className={CLASSES.content_between}>
          <h4 className={CLASSES.items_center}>
            <Image src={CalendarWhiteIcon} className='me-3' /> Appointments
          </h4>
          <Button
            onClick={() => navigate('/appointment/create')}
            className={CLASSES.items_center + ' bg-blue'}
          >
            <Image src={PlusIcon} className={'me-2'} /> Add New Appointment
          </Button>
        </div>

        <div className='mb-5 mt-3 pt-4 border-radius-10 black-shadow-15'>
          <div className={CLASSES.content_between + ' px-4 mb-4'}>
            <div className='px-0 px-md-2' style={{ width: '45%' }}>
              <SearchFilter handleSearch={handleSearch} placeholder={'Appointment ID'} />
            </div>
            {/* <div className='px-0 px-md-2' style={{ minWidth: '35%' }}>
              <DropDownX label='Sort by : ' />
            </div> */}
          </div>

          <Table
            columns={columns(navigate, deleteClick)}
            data={items}
            progressPending={isLoading}
            paginationType={PAGINATION_TYPES.APPOINTMENTS}
            customStyle={{
              rows: {
                style: {
                  borderTop: '0px solid #E0E0E0',
                  borderBottom: '1px solid #E0E0E0 !important',
                  display: 'flex !important',
                  alignItems: 'center !important',
                  padding: 15,
                },
              },
            }}
          />
        </div>
      </div>
    </Container>
  )
}
