import { Suspense } from 'react'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import Header from '../header'
import Sidebar from '../sidebar/Sidebar'
import { useDispatch, useSelector } from 'react-redux'
import { LOADING_NAME } from '../../utils/constants'
import FullPageLoader from '../loader/FullPageLoader'
import React from 'react'
import { setAlert } from '../../store/reducers'
import { preConfirmAlert } from '../../utils/helperFunctions'
// import { USER_ROLES } from '../../utils/userRole'

function RequireAuth({ children }) {
  const { user } = useSelector((state) => state.auth)
  let location = useLocation()

  if (!user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to='/login' state={{ from: location }} replace />
  }

  return children
}

export default function MainLayout() {
  const dispatch = useDispatch()
  // const navigate = useNavigate()
  // const { pathname } = useLocation()
  const isLoading = useSelector(({ loader }) => loader[LOADING_NAME.COMMON])
  // const { user_type_id } = useSelector((state) => state.auth)
  const { show, title, message, success } = useSelector(({ alert }) => alert)

  // React.useEffect(() => {
  //   // Redirect based on user role
  //   if (pathname == '/') {
  //     if (user_type_id == USER_ROLES.WAREHOUSE) {
  //       navigate('/requests', { replace: true })
  //     } else if (user_type_id == USER_ROLES.ACCOUNTS) {
  //       navigate('/requisition', { replace: true })
  //     } else if (
  //       user_type_id == USER_ROLES.PROJECT_MANAGER ||
  //       user_type_id == USER_ROLES.ADMIN
  //     ) {
  //       navigate('/sites', { replace: true })
  //     }
  //   }
  // }, [user_type_id, navigate])

  React.useEffect(() => {
    if (show && success) {
      showAlert({
        icon: 'success',
        timer: 1500,
        // position: 'top-end',
        showConfirmButton: false,
      })
    } else if (show && !success) {
      showAlert({ icon: 'error' })
    }
  }, [show, success])

  const showAlert = async (props) => {
    const response = await preConfirmAlert({
      title: title,
      text: message,
      confirmButtonText: 'OK',
      confirmButton: 'btn btn-primary',
      show: show,
      showCancelButton: false,
      showDenyButton: false,
      ...props,
    })
    if (response.isDismissed || response.isConfirmed) {
      // console.log('confirmed')
      dispatch(
        setAlert({ show: false, title: '', message: '', success: false }),
      )
    }
  }

  
  return (
    <>
      <RequireAuth>
        <Sidebar />
        <div
          style={{ backgroundColor: '#F5F5F5' }}
          className='d-flex flex-column w-100 vh-100 overflow-auto'
        >
          <Header />
          <Suspense fallback={<FullPageLoader isFallback opacity={0.1} />}>
            <Outlet />
          </Suspense>
        </div>
      </RequireAuth>
      {isLoading && <FullPageLoader />}
    </>
  )
}
