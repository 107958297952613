import React from 'react'
import { TICKET_STATUS_OPTIONS } from '../../../utils/static_data'

function HorizontalNavbar({
  data = [
    {
      label: 'All',
      value: '',
    },
    ...TICKET_STATUS_OPTIONS,
  ],
  handleSearch = () => {},
  className='d-flex align-items-center justify-content-between my-2 '
}) {
  const [index, setIndex] = React.useState(0)
  const onClick = (i, item) => {
    setIndex(i)
    handleSearch({
      ticket_status: item.value,
    })
  }
  return (
    <div
      style={{ color: '#676767' }}
      className={className}
    >
      {data.map((item, i) => (
        <div
          onClick={() => onClick(i, item)}
          style={{
            color: i === index ? '#288BCF' : '#676767',
            borderBottom: i === index ? '4px solid #288BCF' : 0,
            marginBottom: i === index ? -3 : 0,
            cursor: 'pointer',
          }}
          className='p-2 mx-2 text-center'
        >
          {item.label}
        </div>
      ))}
    </div>
  )
}

export default HorizontalNavbar
