import React from 'react'
import { CLASSES } from '../../../assets/styles/classes'
import { Button, Image } from 'react-bootstrap'
import BarChart from '../../../components/extras/BarChart'
import DropDownIcon from '../../../assets/icons/common/down-arrow-solid.svg'
import DropDownX from '../../../components/extras/DropDownX'
import request from '../../../utils/request'
import { FETCH_TYPES } from '../../../utils/constants'


  // Create an array with all months from 1 to 12
  const allMonths = Array.from({ length: 12 }, (_, i) => i + 1)
  // Merge the existing data with all months
  const monthly_customers = (data) => allMonths.map((month) => {
    // Find if the month already exists in the original data
    const existingMonth = data?.find((item) => item?.month === month)

    // If it exists, return it; otherwise, create a new entry with total = 0
    // return existingMonth ? existingMonth : { month, total: 0 };
    return existingMonth ? existingMonth?.total : 0
  })
export default function BarGraphCard({ style, icon, name, route, value, data, getHomeState }) {

  const [_data, setData] = React.useState([])
  React.useEffect(() => {
    if (data) {
      setData(data)
    }
  },[data])
  const handleSearch = async (query) => {
      // console.log(query)
      const response = await request(route + query, {
        method: FETCH_TYPES.GET,
      })
      if (response.success) {
        if (name == 'Customers') {
          setData(monthly_customers(response?.monthly_customers))
        } else {
          setData(monthly_customers(response?.monthly_tickets))
        }
      }
  }
  return (
    <div
      className={'border-radius-10 black-shadow-15 overflow-hidden p-3'}
      style={style}
    >
      <div className={CLASSES.content_between}>
        <h6 className='text-gray'>{name} per Year</h6>
        {/* <div className={CLASSES.items_center+'border p-1 px-2'}>
          2023 <Image src={DropDownIcon} className={'ms-2'} />
        </div> */}
        <DropDownX
          items={['2024', '2023', '2022', '2021']}
          isBoldIcon
          handleSearch={handleSearch}
        />
      </div>

      <div className={'bg-white'}>
          <BarChart data={_data} name={name} />
      </div>
    </div>
  )
}
