import React from 'react'
import { Image } from 'react-bootstrap'
import { CLASSES } from '../../../assets/styles/classes'

import DeleteIcon from '../../../assets/icons/common/delete.svg'

import Table from '../../../components/table/Table'

export default function RelevantAssets({ removeAsset, assetsFields, isCreateMode = false }) {
  const columns = (remove = () => {}, omit = false) => [
    {
      name: 'Asset Serial',
      minWidth: '150px',
      cell: ({}, index) => index + 1,
    },
    {
      name: 'Asset Type',
      minWidth: '120px',
      cell: ({ asset_type_id }) => asset_type_id || 'N/A',
    },
    {
      name: 'Asset Name',
      minWidth: '120px',
      cell: ({ asset_name }) => asset_name,
    },
    {
      name: 'Model',
      minWidth: '120px',
      cell: ({ asset_model_no }) => asset_model_no,
    },
    {
      name: 'Guarantee',
      minWidth: '120px',
      cell: ({ asset_warranty_period }) => asset_warranty_period,
    },
    // {
    //   name: 'Validity',
    //   minWidth: '180px',
    //   style: { color: 'red' },
    //   cell: ({}) => '2y 364 day left',
    //   omit: isCreateMode,
    // },
    {
      name: 'Action',
      width: '120px',
      style: { paddingRight: 0 },
      right: true,
      omit: !isCreateMode,
      cell: (row, i) => {
        return (
          <div
            style={{ height: 10, width: 50 }}
            className={CLASSES.items_center + 'overflow-visible'}
            onClick={() => remove(i)}
          >
            <div
              style={{ height: 50, width: 50, backgroundColor: 'white' }}
              className={CLASSES.items_center + 'ps-3 pointer'}
            >
              <Image src={DeleteIcon} />
            </div>
          </div>
        )
      },
    },
  ]

  return (
    <div className='my-5'>
      <div className={CLASSES.content_between}>
        <h5 className={CLASSES.items_center}>Relevant Assets</h5>
      </div>

      <div className='mb-5 mt-3 border-radius-10 black-shadow-15'>
        <Table
          columns={columns(removeAsset)}
          data={assetsFields}
          pagination={false}
          customStyle={{
            rows: {
              style: {
                borderTop: '0px solid #E0E0E0',
                borderBottom: '0px solid #E0E0E0 !important',
                display: 'flex !important',
                alignItems: 'center !important',
                padding: 15,
              },
            },
          }}
        />
      </div>
    </div>
  )
}
