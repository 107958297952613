import ReactPaginate from 'react-paginate'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentPage } from '../../store/reducers'
//
  const CustomPagination = ({
    data,
    // rowsPerPage=10,
    // currentPage=1,
    paginationType,
    handlePagination,
  }) => {
    // const { total, count: dataCount } = data ?? { items: [], total: 50, count: 10 }
    // const count = Math.ceil(total / rowsPerPage)

    const dispatch = useDispatch()
    const { rowsPerPage, total, count, currentPage } = useSelector(
      ({ pagination }) => pagination[paginationType || 'EMPLOYEES'],
    )
    const totalPage = Math.ceil(total / rowsPerPage)
    return (
      <div className="d-flex flex-wrap align-items-center  justify-content-center">
        <div className="pr-0 ml-2">
          <ReactPaginate
            previousLabel="<"
            nextLabel=">"
            breakLabel="..."
            pageCount={totalPage || 1}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            forcePage={currentPage !== 0 ? currentPage - 1 : 0}
            // onPageChange={(page) => handlePagination(page)}
            onPageChange={(page) => {
              // onChangePage(page.selected + 1)
              dispatch(
                setCurrentPage({
                  key: paginationType,
                  currentPage: page.selected + 1,
                }),
              )
              return page.selected + 1
            }}
            activeClassName="active"
            activeLinkClassName="bg-blue border-0"
            pageClassName="page-item"
            nextLinkClassName="page-link border-radius-5 px-3 py-2 text-gray text-bold"
            nextClassName="page-item next"
            previousClassName="page-item prev"
            previousLinkClassName="page-link border-radius-5 px-3 py-2 text-gray text-bold"
            pageLinkClassName="page-link mx-1 border-radius-5 px-3 py-2 text-gray text-bold"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination react-paginate separated-pagination pagination-sm justify-content-center pr-1 mt-1"
          />
        </div>
      </div>
    )
  }


export default CustomPagination
