import React from 'react'
import Image from 'react-bootstrap/Image'
import LogoutIcon from '../../assets/icons/sidebar/log-out.svg'
import { useNavigate } from 'react-router-dom'
import { preConfirmAlert } from '../../utils/helperFunctions'
import AuthService from '../../services/AuthService'
import { useDispatch, useSelector } from 'react-redux'
import { setLoading, userSignOut } from '../../store/reducers'
import { LOADING_NAME } from '../../utils/constants'
import { removeUserData } from '../../utils/localStorage'
// import { USER_TYPES } from '../../utils/static_data'

function Logout({}) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { data, user_type_id } = useSelector((state) => state?.auth)

  const handleLogout = async () => {
    try {
      const { isConfirmed } = await preConfirmAlert({
        title: 'Are you sure to logout?',
        text: '',
        confirmButtonText: 'Yes',
        denyButtonText: 'No',
        confirmButton: 'btn btn-danger',
        denyButton: 'btn btn-primary ms-3',
      })
      if (isConfirmed) {
        dispatch(setLoading({ key: LOADING_NAME.COMMON, value: true }))

        navigate('/login', { replace: true })
        removeUserData()
        dispatch(userSignOut())
        
        // const response = await AuthService.logout()
        // if (response.success) {
        //   navigate('/login', { replace: true })
        //   removeUserData()
        //   dispatch(userSignOut())
        // }
      }
    } catch (error) {
      console.log(error)
    } finally {
      dispatch(setLoading({ key: LOADING_NAME.COMMON, value: false }))
    }
  }

  return (
    <div
      onClick={handleLogout}
      className='p-2 px-4 item d-flex align-items-center'
    >
      <Image src={LogoutIcon} className='me-2 svg-icon' />
      <span className='text-red'>Sign Out</span>
    </div>
  )
}

export default Logout
