import React, { useState } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  FormGroup,
  Image,
  Container,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import ReactstrapInput from "../../../components/form/input-fields/ReactstrapInput";
import DatePicker from "../../../components/form/input-fields/DatePicker";
import TimePicker from "../../../components/form/input-fields/TimePicker";
import CalendarWhiteIcon from "../../../assets/icons/sidebar/calendar-minus.svg";
import { CLASSES } from "../../../assets/styles/classes";
import { AppointmentService } from "../../../services";
import REST_API from "../../../services/API_Routes";
import { addOrUpdatePaginationData } from "../../../store/actions/pagination";
import * as yup from "yup";
import { CustomerService } from "../../../services";
import AsyncReactSelect from "../../../components/form/input-search/AsyncReactSelect";
import { useNavigate, useParams } from "react-router-dom";
import { setAlert, setLoading } from "../../../store/reducers";
import { LOADING_NAME } from "../../../utils/constants";
import ReactSelect from "../../../components/form/input-fields/ReactSelect";
import { viewDateFormat, viewTimeFormat } from "../../../utils/helperFunctions";
import moment from "moment";

const appointmentSchema = yup.object().shape({
  ref_customer_id: yup.object().shape({
    lebel: yup.string(),
    value: yup.string(),
  }),
  ref_status_id: yup.object().shape({
    lebel: yup.string(),
    value: yup.string(),
  }),
  appointment_date: yup.date().required("Date is required."),
  appointment_time: yup.date().required("Time is required."),
  appointment_type: yup.string(),

});

const statusOptions = [
  { appointment_status_id: 1, appointment_status_name: "Pending" },
  { appointment_status_id: 2, appointment_status_name: "Confirmed" },
  { appointment_status_id: 3, appointment_status_name: "Cancelled" },
];

const options = statusOptions.map((item) => ({
  label: item.appointment_status_name,
  value: item.appointment_status_id,
}));

export default function CreateAppointment() {
  const dispatch = useDispatch();
  
  const navigate = useNavigate();
  const { id: AppointmentID } = useParams();
  const [isLoading, setIsLoading] = React.useState(false);
  const [status, setstatus] = React.useState(options);
  const [defaultStatus] = React.useState(options[0]);

  const {
    formState: { errors },
    control,
    reset,
    handleSubmit,
    setValue,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(appointmentSchema),
    defaultValues: {
      ref_status_id: defaultStatus,
    },
  });


  const getData = async () => {
    dispatch(setLoading({ key: LOADING_NAME.COMMON, value: true }));
    try {
      const response = await AppointmentService.view({
        route: REST_API.APPOINTMENT_EDIT_VIEW + AppointmentID,
      });
      if (response.success) {
        reset({
          ...response?.data,
          appointment_time: moment(response?.data?.appointment_time, 'HH:mm:ss').toDate(),
          ref_status_id: response?.data?.ref_status_id 
            ? {
                value: response?.data?.status?.appointment_status_id,
                label: response?.data?.status?.appointment_status_name	,
              }
            : 1,
            ref_customer_id: response?.data?.ref_customer_id 
            ? {
                value: response?.data?.customer?.customer_id,
                label: `${response?.data?.customer?.customer_first_name} ${response?.data?.customer?.customer_last_name || ''}`,
              }
            : 1,

        });
      }
    } catch (error) {
    } finally {
      dispatch(setLoading({ key: LOADING_NAME.COMMON, value: false }));
    }
  };

  React.useEffect(() => {
    if (AppointmentID) {
      getData();
    }
  }, [AppointmentID]);

  const loadSuggestions = React.useCallback((query, callback) => {
    CustomerService.get({
      page: 1,
      per_page: 10,
      customer_active: "1",
      customer_name: query,
    })
      .then((res) => {
        const suggestions = res?.data?.data?.map((item) => ({
          label:
            item.customer_first_name +
            (item.customer_last_name ? " " + item.customer_last_name : ""),
          value: item.customer_id,
        }));
        callback(suggestions);
      })
      .catch((error) => {
        alert(error);
      });
  }, []);
console.log(errors)
 
  const onSubmit = async (values) => {
    values.appointment_date = values.appointment_date ? viewDateFormat(values.appointment_date, 'YYYY-MM-DD'): null;
    values.appointment_time = values.appointment_time ? viewTimeFormat(values.appointment_time) : null;
    values.ref_status_id = values.ref_status_id ? values.ref_status_id.value : null;
    values.ref_customer_id = values.ref_customer_id ? values.ref_customer_id.value : null;

    if (AppointmentID) {
      values.id = AppointmentID;
    }

    setIsLoading(true);

    try {
      const response = await AppointmentService.create_or_update_appoinment(
        values
      );

      if (response?.success) {
        dispatch(
          setAlert({
            show: true,
            success: true,
            title: `Successfully Completed`,
            message: "",
          })
        );
        navigate(-1);
      } else {
        dispatch(
          setAlert({
            show: true,
            message: response?.error_msg || "Something went wrong",
          })
        );
      }
    } catch (error) {
      console.log("error", error);
      dispatch(setAlert({ show: true, message: "Something went wrong" }));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container fluid className="p-4">
      <div className={`${CLASSES.content_between} mb-4`}>
        <h4 className={CLASSES.items_center}>
          <Image src={CalendarWhiteIcon} className="me-3" /> Add New Appointment
        </h4>
      </div>
      <Form className="d-flex flex-column justify-content-between">
        <div className="bg-white card border-0 black-shadow-15 p-4 px-5">
          <Row className={CLASSES.content_center}>
            <Col lg={6}>
              <Row>
                {/* <ReactstrapInput
                  label='Appointment ID'
                  name='appointment_id'
                  type='text'
                  placeholder='Enter appointment ID (or generate automatically)'
                  errorMessage={errors?.appointment_id?.message}
                  control={control}
                  noGrid={true}
                /> */}
                {/* <ReactstrapInput
                  label='Customer Name'
                  name='customer_name'
                  type='text'
                  placeholder='Enter customer name'
                  errorMessage={errors?.customer_name?.message}
                  control={control}
                  noGrid={true}
                /> */}
                <AsyncReactSelect
                  {...{
                    label: "Customer Name",
                    name: "ref_customer_id",
                    type: "text",
                    placeholder: "Select Customer",
                    errorMessage: errors?.ref_customer_id?.value?.message,
                    loadSuggestions,
                    control,
                    required: true,
                    noGrid: true,
                    isClearable: true,
                  }}
                />
              </Row>
              {/* <Row>
                <ReactstrapInput
                  label="Email"
                  name="customer_email"
                  type="email"
                  placeholder="Enter customer email"
                  errorMessage={errors?.customer_email?.message}
                  control={control}
                  xxl="6"
                  xl="6"
                  lg="6"
                />
                <ReactstrapInput
                  label="Phone Number"
                  name="customer_phone"
                  type="tel"
                  placeholder="Enter customer phone number"
                  errorMessage={errors?.customer_phone?.message}
                  control={control}
                  xxl="6"
                  xl="6"
                  lg="6"
                />
              </Row> */}
              <Row>
                <DatePicker
                  label="Date"
                  name="appointment_date"
                  type="date"
                  errorMessage={errors?.appointment_date?.message}
                  control={control}
                  xxl="6"
                  xl="6"
                  lg="6"
                  required
                />
                <TimePicker
                  label="Time"
                  name="appointment_time"
                  type="time"
                  errorMessage={errors?.appointment_time?.message}
                  control={control}
                  xxl="6"
                  xl="6"
                  lg="6"
                  required
                />
              </Row>
              <Row>
              

                <ReactstrapInput
                  label="Type"
                  name="appointment_type"
                  type="text"
                  placeholder="Enter appointment type"
                  errorMessage={errors?.appointment_type?.message}
                  control={control}
                  xxl="6"
                  xl="6"
                  lg="6"
                />

                 <ReactSelect
                  label="Status"
                  name="ref_status_id"
                  placeholder="Select here"
                  errorMessage={errors?.ref_status_id?.value?.message}
                  control={control}
                  options={status}
                  isClearable
                  xxl="6"
                  xl="6"
                  lg="6"
                />
                
              </Row>
              <Row>
                <ReactstrapInput
                  label="Note"
                  name="appointment_note"
                  type="textarea"
                  placeholder="Write note"
                  errorMessage={errors?.appointment_note?.message}
                  control={control}
                  rows="3"
                  noGrid={true}
                />
              </Row>
              <Row className="mt-2">
                <Col className="d-flex mt-1">
                  <FormGroup className="w-100">
                    <Button
                      className="mr-0 w-100 bg-blue border-0"
                      color="primary"
                      onClick={handleSubmit(onSubmit)}
                    >
                      Create New Appointment
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Form>
    </Container>
  );
}
